import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    ACTIVE_POPUP

    SET_ON_LOGIN_CALLBACK_PROPS

    SET_SHOW_LOGIN_POPUP
    SET_LOGIN_HEADING
`,
    {
        prefix: "wishlistloginpopup/"
    }
);
