import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Configuration from "../../../components/configuration";
import { getBookingCount, getCfExperimentVariants, setCfUserData } from "../../../components/configuration/actions";
import { checkCfOffer, clearFormData, setCfStatus, getRtdAuthToken } from "../../../components/mobile/cf-popup/actions";

export default function withMobileConfiguration(Component) {
    const WrapperComponent = (props) => {

        const {
            userId,
            secureToken,
            isLoggedIn,
            getBookingCountConnect = () => {},
            getCfExperimentVariantsConnect = () => {},
            checkCfOfferConnect = () => {},
            setCfUserDataConnect = () => {},
            clearFormDataConnect = () => {},
            setCfStatusConnect = () => {},
            getRtdAuthTokenConnect = () => {}
        } = props || {};

        useEffect(() => {
            const fetchUserCfData = async () => {
                try {
                    if (userId && secureToken) {
                        getBookingCountConnect(secureToken);
                        getCfExperimentVariantsConnect(userId);
                        getRtdAuthTokenConnect(userId);
                        const data = await checkCfOfferConnect(userId);
                        setCfUserDataConnect(data);
                    }

                } catch (_) {
                    //
                }
            };
            fetchUserCfData();
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [userId, secureToken]);

        useEffect(() => {
            if (!isLoggedIn) {
                clearFormDataConnect();
                setCfStatusConnect("");
            }
        }, [isLoggedIn]);

        return (
            <Configuration type={"mobile"}>
                <Component {...props}/>
            </Configuration>
        );
    };

    WrapperComponent.propTypes = {
        userId: PropTypes.string,
        secureToken: PropTypes.string,
        isLoggedIn: PropTypes.bool,
        getBookingCountConnect: PropTypes.func,
        getCfExperimentVariantsConnect: PropTypes.func,
        clearFormDataConnect: PropTypes.func,
        setCfStatusConnect: PropTypes.func,
        getRtdAuthTokenConnect: PropTypes.func
    };

    const mapStateToProps = ({
        user: {
            userId,
            secureToken,
            isLoggedIn
        }
    }) => ({
        userId,
        secureToken,
        isLoggedIn
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        getBookingCountConnect: getBookingCount,
        getCfExperimentVariantsConnect: getCfExperimentVariants,
        checkCfOfferConnect: checkCfOffer,
        setCfUserDataConnect: setCfUserData,
        clearFormDataConnect: clearFormData,
        setCfStatusConnect: setCfStatus,
        getRtdAuthTokenConnect: getRtdAuthToken
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(WrapperComponent);

}
