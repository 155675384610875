import { createReducer } from "../../../utils/redux/create-reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { getPreAppliedFilter } from "../../../utils/filters-v3/get-pre-applied-filters";
import { getUpdatedAppliedFilters, cleatSelectedFilterCategory, clearBulkSelectedFilters } from "../../../utils/filters-v3/get-updated-applied-filters";
import { getUpdatedRecentSearches } from "../../../utils/recent-searches";
import Types from "./types";
import { FILTER_TAB_TYPES } from "../../../utils/filters-v3/constant";

export const INITIAL_STATE = {
    isSSR: false,
    isLoading: false,
    isFetched: false,
    data: [],
    selectedOnScreenFilter: {
        activeOnScreenFilter: "",
        isModalOpen: false
    },
    appliedFilters: {},           // Filters which are applied in the listing.
    selectedFilters: {},           // Filters which are selected but not yet applied in the listing
    clearModal: {
        isClearModal: false,
        isAppliedFilter: true
    },
    filterCount: 0,
    lastAppliedFilters: {},
    activeFilterTab: FILTER_TAB_TYPES.FILTER,
    recentSearches: [],
    isIndiaPage: false,
    indiaPageUrl: "",
    filterSearchQuery: null,
    fetchedFilterCityId: null,
    selectedFiltersCheckout: {},
    guideData: {},
    showLoader: false,
    filterAppliedFromFilterPage: false
};

const persistConfig = {
    key: "filtersMobileIndia",
    storage,
    whitelist: ["lastAppliedFilters", "recentSearches", "showLoader"]
};

export const fetchFiltersInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const fetchFiltersSuccess = (state = INITIAL_STATE, { widgets, count }) => {
    return {
        ...state,
        isFetched: true,
        isLoading: false,
        data: [
            ...widgets
        ],
        filterCount: count
    };
};

export const fetchFiltersFailure = (state) => {
    return {
        ...INITIAL_STATE,
        isFetched: true,
        data: [],
        isLoading: false,
        fetchedFilterCityId: state.fetchedFilterCityId
    };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const updateSSRAppliedFilters = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        appliedFilters: getPreAppliedFilter(data, state.data, "mSite")
    };
};

export const updateSelectedOnScreenFilter = (state = INITIAL_STATE, { selectedOnScreenFilter }) => {
    return {
        ...state,
        selectedOnScreenFilter: {
            ...state.selectedOnScreenFilter,
            ...selectedOnScreenFilter
        }
    };
};

// export const updateFilterOptionData = (state = INITIAL_STATE, { data }) => {
//     return {
//         ...state,
//         selectedFilters: getUpdatedAppliedFilters(data, state.selectedFilters, data),
//         selectedFiltersCheckout: getUpdatedAppliedFilters(data, state.selectedFiltersCheckout, data)
//     };
// };

export const updateFilterOptionData = (state = INITIAL_STATE, { data }) => {
    let updatedState = { ...state };
    if (Array.isArray(data)) {
        data.forEach(action => {
            updatedState = {
                ...updatedState,
                selectedFilters: getUpdatedAppliedFilters(action, updatedState.selectedFilters)
            };
        });
    } else {
        updatedState = {
            ...updatedState,
            selectedFilters: getUpdatedAppliedFilters(data, updatedState.selectedFilters),
            selectedFiltersCheckout: getUpdatedAppliedFilters(data, updatedState.selectedFiltersCheckout)
        };
    }
    return updatedState;
};

export const updateAppliedFilters = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        appliedFilters: data,
        selectedFilters: {}
    };
};

export const copyAppliedToSelectedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: state.appliedFilters,
        selectedFiltersCheckout: state.appliedFilters
    };
};

export const clearSelectedFilterCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: cleatSelectedFilterCategory(data, state.selectedFilters, state.data),
        selectedFiltersCheckout: cleatSelectedFilterCategory(data, state.selectedFiltersCheckout, state.data)
    };
};

export const clearBulkSelectedFilterCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        selectedFilters: clearBulkSelectedFilters(data, state.selectedFilters, state.data),
        selectedFiltersCheckout: clearBulkSelectedFilters(data, state.selectedFiltersCheckout, state.data)
    };
};

export const clearAppliedFilterCategory = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        appliedFilters: cleatSelectedFilterCategory(data, state.appliedFilters)
    };
};

export const copySelectedToLastAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        lastAppliedFilters: state.selectedFilters
    };
};

export const setLastAppliedFilter = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        lastAppliedFilters: { ...data }
    };
};

export const updateFilterTabType = (state = INITIAL_STATE, { activeFilterTab }) => {
    return {
        ...state,
        activeFilterTab
    };
};

export const updateRecentSearches = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        recentSearches: getUpdatedRecentSearches(data, state.recentSearches)
    };
};

export const setIsIndiaPage = (state = INITIAL_STATE, { isIndiaPage }) => {
    return {
        ...state,
        isIndiaPage
    };
};

export const setIndiaPageUrl = (state = INITIAL_STATE, { indiaPageUrl }) => {
    return {
        ...state,
        indiaPageUrl
    };
};

export const setFilterSearchQuery = (state = INITIAL_STATE, { filterSearchQuery }) => {
    return {
        ...state,
        filterSearchQuery
    };
};

export const setFetchedFilterCityId = (state = INITIAL_STATE, { fetchedFilterCityId }) => {
    return {
        ...state,
        fetchedFilterCityId
    };
};

export const updateSelectedFilters = (state = INITIAL_STATE, { selectedFilters }) => {
    return {
        ...state,
        selectedFilters
    };
};

export const resetSelectedFilter = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFilters: {}
    };
};

export const resetSelectedFilterCheckout = (state = INITIAL_STATE) => {
    return {
        ...state,
        selectedFiltersCheckout: {}
    };
};

export const resetAppliedFilters = (state = INITIAL_STATE) => {
    return {
        ...state,
        appliedFilters: {}
    };
};

export const getGuideData = (state = INITIAL_STATE, {guideData}) => {
    return {
        ...state,
        guideData
    };
};

export const toggleLoader = (state = INITIAL_STATE) => {
    return {
        ...state,
        showLoader: !state.showLoader
    };
};

export const setFilterAppliedFromFilterPage  = (state = INITIAL_STATE, { filterAppliedFromFilterPage }) => {
    return {
        ...state,
        filterAppliedFromFilterPage
    };
};

export const HANDLERS = {
    [Types.FETCH_FILTERS]: fetchFiltersInit,
    [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
    [Types.FETCH_FILTERS_FAILURE]: fetchFiltersFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.UPDATE_SELECTED_ON_SCREEN_FILTER]: updateSelectedOnScreenFilter,
    [Types.UPDATE_FILTER_OPTION_DATA]: updateFilterOptionData,
    [Types.CLEAR_SELECTED_FILTER_CATEGORY]: clearSelectedFilterCategory,
    [Types.CLEAR_APPLIED_FILTER_CATEGORY]: clearAppliedFilterCategory,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.COPY_APPLIED_TO_SELECTED_FILTER]: copyAppliedToSelectedFilters,
    [Types.UPDATE_SSR_APPLIED_FILTER]: updateSSRAppliedFilters,
    [Types.COPY_SELECTED_TO_LAST_APPLIED_FILTERS]: copySelectedToLastAppliedFilters,
    [Types.SET_LAST_APPLIED_FILTER]: setLastAppliedFilter,
    [Types.UPDATE_FILTER_TAB_TYPE]: updateFilterTabType,
    [Types.UPDATE_RECENT_SEARCHES]: updateRecentSearches,
    [Types.SET_INDIA_PAGE]: setIsIndiaPage,
    [Types.SET_INDIA_PAGE_URL]: setIndiaPageUrl,
    [Types.SET_FILTER_SEARCH_QUERY]: setFilterSearchQuery,
    [Types.SET_FETCHED_FILTER_CITY_ID]: setFetchedFilterCityId,
    [Types.UPDATE_SELECTED_FILTERS]: updateSelectedFilters,
    [Types.SET_RESET_SELECTED_FILTERS]: resetSelectedFilter,
    [Types.SET_RESET_SELECTED_FILTERS_CHECKOUT]: resetSelectedFilterCheckout,
    [Types.RESET_APPLIED_FILTERS]: resetAppliedFilters,
    [Types.CLEAR_BULK_SELECTED_FILTER_CATEGORY]: clearBulkSelectedFilterCategory,
    [Types.GET_FILTER_GUIDE]: getGuideData,
    [Types.TOGGLE_LOADER]: toggleLoader,
    [Types.UPDATE_FILTER_APPLIED_FROM_FILTER]: setFilterAppliedFromFilterPage
};

export default persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
