import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        TOGGLE_LOCATION_PICKER
        LOCATION_SELECTETION_TYPE
        LOCATION_PAGE_TYPE
        IS_CTA_CLICKED_WITHOUT_PINCODE
        COMING_SOON_POPUP
        FETCH_CI_AVAILABLE_SUCCESS
        SET_MANUAL_PINCODE

        CHANGE_LOCATION_AFTER_INDIA_PAGE

        UPDATE_CUSTOM_LOCATION_CAR_CARD
        SET_CITY_SEARCH_FOCUS
    `,
    {
        prefix: "locationpickerpopup/"
    }
);
