import Types from "./types";
import { AuthService } from "../../../service/auth-service";
import { LOGIN_HEADING_CONSTANTS } from "../../../constants/login-heading-constants";

const sendOTP = (mobile) => () => {
    return new Promise((resolve, reject) => {
        AuthService.sendOTP(mobile)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const verifyOTP = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.verifyOTP(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const login = (params) => () => {
    return new Promise((resolve, reject) => {
        AuthService.login(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const setActiveLoginPopup = (popup, openNewLogin = false, loginEventSource = "") => ({
    type: Types.ACTIVE_POPUP,
    popup,
    openNewLogin,
    loginEventSource
});

const setOnLoginCallbackProps = (callbackType = null, payload = null, customFunction = () => {}) => ({
    type: Types.SET_ON_LOGIN_CALLBACK_PROPS,
    callbackType,
    payload,
    customFunction
});

const setShowLoginPopup = (showLoginPopup) => ({
    type: Types.SET_SHOW_LOGIN_POPUP,
    showLoginPopup
});

const logout = () => () => {
    return new Promise((resolve, reject) => {
        AuthService.logout().then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

const setLoginHeading = (loginHeading, newLoginHeading = LOGIN_HEADING_CONSTANTS.DEFAULT) => ({
    type: Types.SET_LOGIN_HEADING,
    loginHeading,
    newLoginHeading
});

export {
    sendOTP,
    verifyOTP,
    login,
    setActiveLoginPopup,
    setOnLoginCallbackProps,
    setShowLoginPopup,
    logout,
    setLoginHeading
};
