import { popularCarListParser } from "../../../utils/helpers/popular-car-list-parser";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    popularCityListsAndAvailableBrands: [],
    isPopularCityListSSR: false
};

export const fetchCityListAndBrands = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchCityListAndBrandsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        popularCityListsAndAvailableBrands: popularCarListParser(data)
    };
};

export const fetchCityListAndBrandsFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const updatePopularCityListSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isPopularCityListSSR: isSSR };
};

export const HANDLERS = {
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS]: fetchCityListAndBrands,
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE]: fetchCityListAndBrandsFailure,
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS]: fetchCityListAndBrandsSuccess,
    [Types.UPDATE_POPULAR_CITY_LIST_SSR_STATUS]: updatePopularCityListSSRStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
