
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    buyCarsHeaderData: {},
    isSSR: false
};

export const setBuyCarsHeaderData = (state = INITIAL_STATE, data) => {
    return { ...state,  buyCarsHeaderData: data};
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const HANDLERS = {
    [Types.SET_BUY_CARS]: setBuyCarsHeaderData,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
