import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    IS_CF_MODAL_VISIBLE
    UPDATE_CF_POPUP_TYPE
    UPDATE_FORM_DETAIL_TYPE
    SET_CURRENT_STEP

    FETCH_FINANCE_QUESTIONS_SUCCESS
    FETCH_FINANCE_QUESTIONS_FAILURE

    SET_FINANCE_PERSONAL_DETAILS
    SET_FINANCE_ADDRESS_DETAILS
    SET_FINANCE_INCOME_DETAILS

    FETCH_CF_DETAILS_SUCCESS
    FETCH_CF_DETAILS_FAILURE

    UPDATE_ACTIVE_FORM

    GET_FINANCE_FORM_DATA_SUCCESS
    CHECK_CF_OFFER_SUCCESS
    CHECK_CF_OFFER_FAILURE

    SUMBIT_FINANCE_ANSWERS_SUCCESS
    SUMBIT_FINANCE_ANSWERS_FAILURE

    SET_ZERO_DP_REDIRECT_FLOW
    SET_FINANCE_CTA_FLOW
    SET_LANDING_PAGE_FLOW
    SET_LANDING_PAGE_FLOW_LABEL
    SET_USER_VARIANT
    SET_CALCULATOR_PA_FLOW
    SET_USER_SELECTED_DP
    SET_USER_SELECTED_TENURE
    RESET_CALCULATOR_VALUE

    GET_ALL_OFFER_SUCCESS
    GET_ALL_OFFER_FAILURE
    SET_CF_MODAL_TYPE
    SET_CF_DROP_TYPE
    IS_POST_BOOKING_CF_FLOW

    SET_CONFIG
    SET_USER_ORDER
    SET_SUBMIT_BY_FLAG
    SET_CF_BANNER_FLOW
    SET_CF_LABEL

    SET_CURRENT_SCREEN_TYPE

    SET_PROGRESS_BAR
    SET_DEALER_OPTION
    SET_DEEPLINK_ROUTE
    SET_DEALER_ERROR
    SET_DEEPLINK_OFFER
    IS_CF_CAMPAIGN
    SET_CF_STATUS

    SET_LOAN_TAB_FLOW
    SET_INTEREST_FORM_FILLED
    SET_CF_SUBMITTED_BY
    SET_DEALER_BANKING_SUCCESS
    SET_CF_OFFER_PAGE_TYPE
    SET_IS_LISTING_PAGE_BANNER_FLOW

    CLEAR_FORM_DATA
    SET_POST_BOOKING_CF_ORDER
    SET_BANK_LOGO_MAP
    SET_DEEPLINK_LOGIN
    TOGGLE_CF_TENURE_POPUP
    SET_RTD_TOKEN
    SET_BANKING_CONSENT_DATA
`,
    {
        prefix: "cfpopup/"
    }
);
