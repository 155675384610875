import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    showCallBackPopup: false,
    showCEPCallBackPopup: false,
    submittedTime: "",
    isTicking: false,
    callbackSource: "",
    askCallIntent: false, // used in c2b - sell marketing page
    isIntentSaved: false // used in c2b - sell marketing page
};

export const updateCallBackPopupStatus = (state = INITIAL_STATE, { showCallBackPopup }) => {
    return { ...state, showCallBackPopup };
};

export const updateCEPCallBackPopupStatus = (state = INITIAL_STATE, { showCEPCallBackPopup }) => {
    return { ...state, showCEPCallBackPopup };
};

export const requestCallbackSubmittedTime = (state = INITIAL_STATE, { submittedTime }) => {
    return { ...state, submittedTime };
};

export const setIsTicking = (state = INITIAL_STATE, { isTicking }) => {
    return { ...state, isTicking };
};

export const setCallbackSource = (state = INITIAL_STATE, { source }) => {
    return { ...state, callbackSource: source };
};

export const setAskCallIntent = (state = INITIAL_STATE, { askCallIntent }) => {
    return { ...state, askCallIntent };
};

export const saveCallbackIntent = (state = INITIAL_STATE, { isIntentSaved }) => {
    return { ...state, isIntentSaved };
};

export const HANDLERS = {
    [Types.UPDATE_REQUEST_CALLBACK_POPUP_STATUS]: updateCallBackPopupStatus,
    [Types.UPDATE_CEP_REQUEST_CALLBACK_POPUP_STATUS]: updateCEPCallBackPopupStatus,
    [Types.REQUEST_CALL_SUBMITTED_TIME]: requestCallbackSubmittedTime,
    [Types.IS_TICKING]: setIsTicking,
    [Types.SET_CALLBACK_SOURCE]: setCallbackSource,
    [Types.SET_ASK_CALL_INTENT]: setAskCallIntent,
    [Types.SAEV_CALLBACK_INTENT]: saveCallbackIntent
};

export default createReducer(INITIAL_STATE, HANDLERS);
