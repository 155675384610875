/* eslint-disable new-cap */
import Types from "./types";
import ConsumerFinanceService from "../../../service/consumer-finance-service";
import { UsedCarService } from "../../../service/used-car-service";
import { C2CSecureService } from "../../../service/c2c-secure-service";
import { OmsAggregatorService } from "../../../service/oms-aggregator-service";
import { getBankLogoMapping } from "../../../utils/helpers/get-bank-logo-mapping";

const showCFModal = (isVisible, currentScreenType) => ({
    type: Types.IS_CF_MODAL_VISIBLE,
    isVisible,
    currentScreenType
});
const updateCFPopUpType = (popUpType) => ({
    type: Types.UPDATE_CF_POPUP_TYPE,
    popUpType
});

const fetchFinanceQuestionsSuccess = (data) => ({
    type: Types.FETCH_FINANCE_QUESTIONS_SUCCESS,
    data
});

const fetchFinanceQuestionsFailure = (error) => ({
    type: Types.FETCH_FINANCE_QUESTIONS_FAILURE,
    error
});

const fetchFinanceQuestions = () => (dispatch) => {
    UsedCarService.getFinanceQuestions().then((response) => {
        dispatch(fetchFinanceQuestionsSuccess(response.data.data));
    }).catch((error) => {
        dispatch(fetchFinanceQuestionsFailure(error));
    });
};

const submitFinanceAnswersSuccess = (data) => ({
    type: Types.SUMBIT_FINANCE_ANSWERS_SUCCESS,
    data
});

const submitFinanceAnswersFailure = (error) => ({
    type: Types.SUMBIT_FINANCE_ANSWERS_FAILURE,
    error
});

const submitFinanceAnswers = (data, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitFinanceAnswers(data, params).then((response) => {
            dispatch(submitFinanceAnswersSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const submitUserCfDetails = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitUserCfDetails(data).then((response) => {
            // dispatch(submitFinanceAnswersSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const submitUserCfDetailsV2 = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitUserCfDetailsV2(data).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const submitUserFormDetails = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitUserFormDetails(data).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            dispatch(submitFinanceAnswersFailure(error));
            reject(error);
        });
    });
};

const setStep = ({ currentStep, tabs }) => {
    return {
        type: Types.SET_CURRENT_STEP,
        currentStep,
        tabs
    };
};

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const setFinanceAddressDetails = (data) => ({
    type: Types.SET_FINANCE_ADDRESS_DETAILS,
    data
});

const setFinanceIncomeDetails = (data) => ({
    type: Types.SET_FINANCE_INCOME_DETAILS,
    data
});

const fetchCFDetailsSuccess = (data) => ({
    type: Types.FETCH_CF_DETAILS_SUCCESS,
    data
});

const fetchCFDetailsFailure = (error) => ({
    type: Types.FETCH_CF_DETAILS_FAILURE,
    error
});

const fetchCFDetails = (mobile) => (dispatch) => {
    UsedCarService.cfOfferDetails(mobile).then((response) => {
        dispatch(fetchCFDetailsSuccess(response.data.data));
    }).catch((error) => {
        dispatch(fetchCFDetailsFailure(error));
    });
};

const fetchCFDetailsSecured = (mobile, appointmentId, token) => (dispatch) => {
    C2CSecureService.cfOfferDetails(mobile, appointmentId, token).then((response) => {
        dispatch(fetchCFDetailsSuccess(response.data.data));
    }).catch((error) => {
        dispatch(fetchCFDetailsFailure(error));
    });
};

const checkCfOfferSuccess = (data) => ({
    type: Types.CHECK_CF_OFFER_SUCCESS,
    data
});

const checkCfOfferFailure = (error) => ({
    type: Types.CHECK_CF_OFFER_FAILURE,
    error
});

const setUserVariant = (userVariant) => ({
    type: Types.SET_USER_VARIANT,
    userVariant
});
const setConfig = (data) => ({
    type: Types.SET_CONFIG,
    data
});

const setCfStatus = (data) => ({
    type: Types.SET_CF_STATUS,
    data
});

const setInterestFormFilled = (data) => ({
    type: Types.SET_INTEREST_FORM_FILLED,
    data
});

const setCfSubmittedBy = (data) => ({
    type: Types.SET_CF_SUBMITTED_BY,
    data
});

const getFinanceFormDataSuccess = (data) => ({
    type: Types.GET_FINANCE_FORM_DATA_SUCCESS,
    data
});

const checkCfOffer = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { user } = getState() || {};
        const { secureToken } = user || {};
        OmsAggregatorService.checkCfOffer({token: secureToken}).then((response) => {
            const { cfUserData } = response.data || {};
            const { submittedBy = "" } = cfUserData || {};
            dispatch(setConfig(response.data.config));
            dispatch(checkCfOfferSuccess(response.data));
            dispatch(setUserVariant(response.data.userVariant));
            dispatch(setCfStatus(response.data.status));
            dispatch(setInterestFormFilled(response.data.interestFormFilled));
            dispatch(setCfSubmittedBy(submittedBy));
            dispatch(getFinanceFormDataSuccess(cfUserData));
            resolve(response.data);
        }).catch((error) => {
            dispatch(checkCfOfferFailure(error));
            reject(error);
        });
    });
};

const updateActiveForm = (activeForm) => ({
    type: Types.UPDATE_ACTIVE_FORM,
    activeForm
});

const getFinanceFormData = (userId) => (dispatch) => {
    ConsumerFinanceService().getFinanceFormData(userId).then((response) => {
        dispatch(getFinanceFormDataSuccess(response.data.data));
    });
};

const formOneInitiate = (payload) => () => {
    return ConsumerFinanceService().formOneInitiate(payload);
};

const setLandingPageFlow = (landingPageFlow) => ({
    type: Types.SET_LANDING_PAGE_FLOW,
    landingPageFlow
});

const setLandingPageFlowLabel = (landingPageFlowLabel) => ({
    type: Types.SET_LANDING_PAGE_FLOW_LABEL,
    landingPageFlowLabel
});

const setFinanceCTAFlow = (financeCtaFlow) => ({
    type: Types.SET_FINANCE_CTA_FLOW,
    financeCtaFlow
});

const setCalculatorPAFlow = (calculatorPaFlow) => ({
    type: Types.SET_CALCULATOR_PA_FLOW,
    calculatorPaFlow
});

const setUserSelectedDp = (userSelectedDp) => ({
    type: Types.SET_USER_SELECTED_DP,
    userSelectedDp
});

const setUserSelectedTenure = (userSelectedTenure) => ({
    type: Types.SET_USER_SELECTED_TENURE,
    userSelectedTenure
});

const resetCalculatorValue = () => ({
    type: Types.RESET_CALCULATOR_VALUE
});

const getAllOffersSuccess = (data) => ({
    type: Types.GET_ALL_OFFER_SUCCESS,
    data
});

const getAllOffersFailure = (error) => ({
    type: Types.GET_ALL_OFFER_FAILURE,
    error
});

const getCalculatorOffers = (params) => (dispatch) => {
    // dispatch(getAllOfferssInitiated());
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCalulatorOffers(params).then((data) => {
            dispatch(getAllOffersSuccess(data.data));
            resolve(data.data);
        }).catch((error) => {
            dispatch(getAllOffersFailure(error));
            reject(error);
        });
    });

};

const setZeroDpRedirectFlow = (zeroDpRedirect) => ({
    type: Types.SET_ZERO_DP_REDIRECT_FLOW,
    zeroDpRedirect
});

const setUserOrder = (userOrder) => ({
    type: Types.SET_USER_ORDER,
    userOrder
});
const setCfBannerFlow = (cfBannerFlow) => ({
    type: Types.SET_CF_BANNER_FLOW,
    cfBannerFlow
});
const setCfModalType = (cfModalType) => ({
    type: Types.SET_CF_MODAL_TYPE,
    cfModalType
});

const setCfDropType = (cfDropType) => ({
    type: Types.SET_CF_DROP_TYPE,
    cfDropType
});

const setIsPostBookingCfFlow = (isPostBookingCf) => ({
    type: Types.IS_POST_BOOKING_CF_FLOW,
    isPostBookingCf
});

const setCfLabel = (cfLabel) => ({
    type: Types.SET_CF_LABEL,
    cfLabel
});
const setSubmitByFlag = (submittedByFlag) => ({
    type: Types.SET_SUBMIT_BY_FLAG,
    submittedByFlag
});

const setCurrentScreentType = (currentScreenType) => ({
    type: Types.SET_CURRENT_SCREEN_TYPE,
    currentScreenType
});

const setProgressBar = (progressBar) => ({
    type: Types.SET_PROGRESS_BAR,
    progressBar
});

const setDealerOption = (data) => ({
    type: Types.SET_DEALER_OPTION,
    data
});

const setDeepLinkRoute = (data) => ({
    type: Types.SET_DEEPLINK_ROUTE,
    data
});

const setDealerError = (data) => ({
    type: Types.SET_DEALER_ERROR,
    data
});

const setDeeplinkOffer = (data) => ({
    type: Types.SET_DEEPLINK_OFFER,
    data
});

const setIsCfCampaign = (data) => ({
    type: Types.IS_CF_CAMPAIGN,
    data
});

const setDealerBankingSuccess = (data) => ({
    type: Types.SET_DEALER_BANKING_SUCCESS,
    data
});

const setLoanTabFlow = (data) => ({
    type: Types.SET_LOAN_TAB_FLOW,
    data
});

const setCfOfferPageType = (data) => ({
    type: Types.SET_CF_OFFER_PAGE_TYPE,
    data
});

const setIsListingPageBannerFlow = (data) => ({
    type: Types.SET_IS_LISTING_PAGE_BANNER_FLOW,
    data
});

const saveUserPersonalData = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().saveUserPersonalData(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const clearFormData = () => ({
    type: Types.CLEAR_FORM_DATA
});

const setPostBookingCfOrder = (data) => ({
    type: Types.SET_POST_BOOKING_CF_ORDER,
    data
});

const getCibilReportUrl = (userId) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCibilReportUrl(userId).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getCkycDetails = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCkycDetails(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateCkycDetails = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().updateCkycDetails(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setBankLogoMap = (data) => ({
    type: Types.SET_BANK_LOGO_MAP,
    data
});

const getBankLogoList = (type) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCfBankList(type).then((response) => {
            const { data: responseData } = response || {};
            const { data: dataList } = responseData || [];
            const logoMap = getBankLogoMapping(dataList);
            dispatch(setBankLogoMap(logoMap));
            resolve(logoMap);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setDeeplinkLogin = (data) => ({
    type: Types.SET_DEEPLINK_LOGIN,
    data
});

const toggleCfTenurePopup = (data) => ({
    type: Types.TOGGLE_CF_TENURE_POPUP,
    data
});

const setRtdAuthToken = (data) => ({
    type: Types.SET_RTD_TOKEN,
    data
});

const setBankingConsentData = (data) => ({
    type: Types.SET_BANKING_CONSENT_DATA,
    data
});

const getRtdAuthToken = (userId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getRtdAuthToken(userId).then(response => {
            const { data } = (response || {}).data || {};
            dispatch(setRtdAuthToken(data));
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const initiatePhysicalKyc = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().initiatePhysicalKyc(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const generateKycOTP = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().generateKycOTP(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const submitOkycDetails = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().submitOkycDetails(payload).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const fetchBankingConsentDetails = (token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.fetchBankingConsentDetails(token).then((response) => {
            dispatch(setBankingConsentData(response.data));
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

export {
    showCFModal,
    updateCFPopUpType,
    fetchFinanceQuestions,
    submitFinanceAnswers,
    setStep,
    fetchCFDetails,
    fetchCFDetailsSecured,
    checkCfOffer,
    setFinancePersonalDetails,
    setFinanceAddressDetails,
    setFinanceIncomeDetails,
    updateActiveForm,
    getFinanceFormData,
    formOneInitiate,
    setZeroDpRedirectFlow,
    setFinanceCTAFlow,
    setLandingPageFlow,
    setLandingPageFlowLabel,
    setUserVariant,
    setCalculatorPAFlow,
    setUserSelectedDp,
    setUserSelectedTenure,
    resetCalculatorValue,
    getCalculatorOffers,
    setUserOrder,
    setCfBannerFlow,
    setCfModalType,
    setCfDropType,
    setIsPostBookingCfFlow,
    setCfLabel,
    setSubmitByFlag,
    submitUserCfDetails,
    setCurrentScreentType,
    setProgressBar,
    setDealerOption,
    setDeepLinkRoute,
    setDealerError,
    setDeeplinkOffer,
    setIsCfCampaign,
    setDealerBankingSuccess,
    setLoanTabFlow,
    setCfOfferPageType,
    setIsListingPageBannerFlow,
    saveUserPersonalData,
    clearFormData,
    setPostBookingCfOrder,
    getCkycDetails,
    updateCkycDetails,
    setCfStatus,
    submitUserCfDetailsV2,
    getCibilReportUrl,
    setDeeplinkLogin,
    getBankLogoList,
    toggleCfTenurePopup,
    getRtdAuthToken,
    initiatePhysicalKyc,
    generateKycOTP,
    submitOkycDetails,
    submitUserFormDetails,
    fetchBankingConsentDetails
};
