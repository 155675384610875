/* eslint-disable max-statements */
/* eslint-disable max-params */
import { C2C_SECURE_API_PATH } from "../constants/api-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const {
    LOAN_DETAILS
} = C2C_SECURE_API_PATH;

const c2cSecureApi = (api) => {

    const cfOfferDetails = (mobile, appointmentId, token, source = "mSite") => {
        return api.get(`${LOAN_DETAILS}/${mobile}/appointmentId/${appointmentId}?source=${source}`, {
            headers: { token }
        });
    };

    return {
        cfOfferDetails
    };
};

export const C2CSecureService = c2cSecureApi(middlewareConfig(MIDDLEWARE_ENUMS.C2C_SECURE_BASE_CONST));

