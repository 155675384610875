import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_BUY_CARS
    UPDATE_SSR_STATUS
    `,
    {
        prefix: "loginPanel/"
    }
);
