import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_ICON_PROPERTY
    SET_CUSTOM_CHAT_DATA
    SET_CHAT_TRIGGER
`,
    {
        prefix: "chaticon/"
    }
);
