import { TRUECALLER_USER_STATUS } from "../../../constants/app-constants";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
export const INITIAL_STATE = {
    data: null,
    isTrueCallerActive: true,
    status: ""
};
export const updateTruecallerDetails = (state = INITIAL_STATE, { data }) => {
    const { name, phoneNumbers, onlineIdentities, addresses, status } = data;
    if (status && status === TRUECALLER_USER_STATUS.USER_REJECTED) {
        return { ...state, status };
    }
    let email;
    if (onlineIdentities) {
        email = onlineIdentities.email;
    }
    let address = "";
    let city = "";
    if (addresses && addresses[0]) {
        address = `${addresses[0].street}, ${addresses[0].zipcode}`;
        city = addresses[0].city;
    }
    const fullName = [name.first, name.last].join(" ");
    const phoneNumber = (phoneNumbers[0]).toString().substring("2");
    return { ...state, data: { name: fullName, phone: phoneNumber, email, address, city } };
};
const resetTruecallerDetails = () => {
    return INITIAL_STATE; //Always return the initial state
};
export const HANDLERS = {
    [Types.UPDATE_TRUECALLER_DETAILS]: updateTruecallerDetails,
    [Types.RESET_TRUECALLER_DETAILS]: resetTruecallerDetails
};
export default createReducer(INITIAL_STATE, HANDLERS);
