import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_WISHLISTED_CARS
    FETCH_WISHLISTED_CARS_SUCCESS
    FETCH_WISHLISTED_CARS_FAILURE

    ADD_TO_WISHLIST
    ADD_TO_WISHLIST_SUCCESS
    ADD_TO_WISHLIST_FAILURE

    REMOVE_FROM_WISHLIST
    REMOVE_FROM_WISHLIST_SUCCESS
    REMOVE_FROM_WISHLIST_FAILURE

    FETCH_SIMILAR_TO_WISHLIST_CARS
    FETCH_SIMILAR_TO_WISHLIST_CARS_SUCCESS
    FETCH_SIMILAR_TO_WISHLIST_CARS_FAILURE

    SHOW_WISHLIST_MODAL
    SET_WISHLIST_APP_IDS

    CLEAR_REMOVED_WISHLIST_CAR_ID
`,
    {
        prefix: "wishlistedbyyou/"
    }
);
