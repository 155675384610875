import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    iconProperties: {
        "home": {
            hide: true
        },
        "car-listing": {
            variation: "collapsible",
            bottom: 100
        },
        "car-details": {
            hide: true
        },
        "car-details-checkout": {},
        "myBookings": {
            bottom: 100
        },
        "buy-used-cars": {
            bottom: 100
        },
        "car-details-faster-checkout": {},
        "facebook-landing": {
            hide: false
        }
    },
    customData: {},
    chatTrigger: false
};

export const setIconProperty = (state = INITIAL_STATE, { page, properties }) => {
    return {
        ...state,
        iconProperties: {
            ...state.iconProperties,
            [page]: {
                ...(state.iconProperties[page] || {}),
                ...properties
            }
        }
    };
};

export const setCustomChatData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        customData: {
            ...data
        }
    };
};

export const setChatTrigger = (state = INITIAL_STATE, { chatTrigger }) => {
    return {
        ...state,
        chatTrigger
    };
};

export const HANDLERS = {
    [Types.SET_ICON_PROPERTY]: setIconProperty,
    [Types.SET_CUSTOM_CHAT_DATA]: setCustomChatData,
    [Types.SET_CHAT_TRIGGER]: setChatTrigger
};

export default createReducer(INITIAL_STATE, HANDLERS);
