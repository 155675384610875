export const popularCarListParser = (data) => {
    const popularCarListData = (data || []).map((d) => {
        const { url, cityName, availableBrands, make } = d;
        const customAvailableBrands = availableBrands.map((b) => {
            return {
                make: b.make
            };
        });
        return { url, cityName, availableBrands: customAvailableBrands, make };
    });
    return popularCarListData;
};
