export const usedCarLeadInfo = {
    customerName: "",
    customerPhoneNumber: "",
    whatsappNotifications: false,
    isFinanced: null,
    isRedirected: false,
    leadFlow: null,
    isHomePageCtaFlow: false
};

export const locationPicker = {
    showLocationPicker: false,
    locationSelectionType: "",
    pageType: "",
    isCTAClickedWithoutPincode: {
        type: "",
        meta: {}
    }
};

export const leadData = {
    brand: { displayText: "", id: null },
    model: { displayText: "", id: null },
    fuelType: { displayText: "" },
    ownerName: "",
    transmission: { displayText: "" },
    year: { displayText: "", id: null, year_id: null },
    variant: { displayText: "", id: null, variantType: null },
    registrationState: { displayText: "", id: null },
    branch: { displayText: "", id: null, selectionType: "city" },
    rto: { displayText: "", id: null },
    currentStep: "brand",
    kilometersDriven: { displayText: "", id: null },
    currentStepIndex: 0,
    currentTabIndex: 0,
    prevTabIndex: 0,
    proceedCTA: false,
    phoneNumber: { displayText: "", id: null },
    token: null,
    error: null,
    activateLastStep: false,
    priceRange: {},
    source: null,
    isLeadFormChanged: false,
    isTruecaller: false,
    isTruecallerCancelled: false,
    isFocusOrBlurInput: false,
    flowName: "default",
    ctaLocation: null,
    experimentalT0CTAClicked: false,
    isAllStepFill: false,
    stepValue: 5000,
    isTutorialEnabled: true,
    ackoAppointmentExtras: {},
    whatsappConsent: null,
    isWebQuoteAccepted: null,
    activeScreen: "carCondition",
    fgvgeScreen: "offerPriceAcceptionChoice",
    additionalQuestions: [],
    userExpectedPrice: "",
    fairCarReasons: [],
    carRegistrationNo: "",
    variantActiveScreen: "fueltype",
    isSellOnline: false,
    leadScore: null,
    askUserIntent: false,
    userIntent: "",
    narrowExperimentType: "",
    similarCars: [],
    showCarLoader: false,
    selectedCityId: null
};

export const registratioNo = {
    userCarRegistrationNo: "",
    dsVariantList: [],
    carRegistrationInfo: null,
    error: null,
    isChangingPreFetchedDetail: false,
    allDetailFetched: false,
    isError: false
};

export const settings = {
    confirmation: {},
    isCitySelectionSkipped: false,
    leadSource: "original",
    dealerCode: ""
};
