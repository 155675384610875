import { createTypes } from "../../../../utils/redux/create-types";

export default createTypes(
    `
    IS_DATA_LOADING
    SET_INTENT_DATA_INIT
    SET_INTENT_DATA_SUCCESS
    SET_INTENT_DATA_FAILURE
    SET_INTENT_UI_CONFIG
    SET_INTENT_UI_CONFIG_INIT
    SET_INTENT_UI_CONFIG_FAIL
`,
    {
        prefix: "loans/"
    }
);
