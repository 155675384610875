import { NAME_ERROR } from "../../../constants/cf-constants";

export const FIELD_TYPE = {
    NAME: "NAME",
    COMPANY_NAME: "COMPANY_NAME"
};

export const FORM_STEPS = {
    PERSONAL: "PERSONAL",
    ADDRESS: "ADDRESS",
    INCOME: "INCOME"
};

export const HOUSE_TYPE = {
    OWNED: "OWNED",
    RENTED: "RENTED"
};

export const ADDRESS_INFO = {
    HOUSE_NO: "houseNo",
    STREET: "street",
    pincode: "pincode",
    CITY: "city",
    STATE: "state",
    HOUSE_TYPE: "houseType"
};

export const EMPLOYMENT_TYPE = {
    SALARIED: "SALARIED",
    SELF_EMPLOYED: "SELF_EMPLOYED"
};

export const PROGRESS_INFO = {
    [FORM_STEPS.PERSONAL]: {
        heading: "PERSONAL DETAILS",
        stepNumber: 1
    },
    [FORM_STEPS.ADDRESS]: {
        heading: "CORRESPONDENCE ADDRESS",
        stepNumber: 2
    },
    [FORM_STEPS.INCOME]: {
        heading: "INCOME DETAILS",
        stepNumber: 3
    }
};

export const VERIFICATION_METHOD = {
    NET_BANKING: "NET_BANKING",
    MANUAL: "MANUAL"
};

export const VERIFICATION_MODAL_TYPE = {
    SELECT_BANK: "SELECT_BANK",
    CHOOSE_METHOD: "CHOOSE_METHOD",
    UPLOAD_STATEMENT: "UPLOAD_STATEMENT",
    SHOW_BANK_STATEMENT: "SHOW_BANK_STATEMENT",
    SUCCESS_MODEL_CANCEL: "SUCCESS_MODEL_CANCEL",
    SUCCESS_MODEL: "SUCCESS_MODEL",
    BAJAJ_WAIT_LOADER: "BAJAJ_WAIT_LOADER",
    OFFER_SPLASH_SCREEN: "OFFER_SPLASH_SCREEN"
};

export const FORM_SOURCE = {
    HOMEPAGE: "Homepage",
    LISTING_CATALOGUE: "Eligibility_banner_lisiting",
    LISTING_TOOLTIP: "Eligibility_tooltip_listing",
    LISTING_ZERO_DP_BANNER: "Listing_Page_ZeroDP_banner",
    CAR_DETAILS_ZERO_DP_BANNER: "Car_Details_ZeroDP_banner",
    DETAILS_CUSTOMIZE_EMI: "Details_Customize_EMI",
    CHECKOUT: "Checkout",
    POST_BOOKING: "Post_Booking",
    POST_CHECKOUT: "post-checkout",
    HOMEPAGE_GET_FINANCING: "Homepage_Get_Financing",
    HOMEPAGE_ZERO_DP: "Homepage_ZeroDP",
    LISTING_PAGE_72_MONTHS: "Listing_Page_72_Months",
    EMI_CALCULATOR_CHECK_ELIGIBILITY_BANNER: "EMI_Calculator_Check_Eligibility_Banner",
    LANDING_PAGE: "landing page",
    MENU: "Menu",
    DURING_CHECKOUT_BOOKING_STARTED: "During_Checkout_Booking_Started",
    DURING_CHECKOUT_ORDER_SUMMARY: "During_Checkout_Order_Summary",
    POST_CHECKOUT_ORDER_SUMMARY: "order_summary",
    HOMEPAGE_72_MONTHS: "Homepage_72_Months",
    DEEPLINK: "Deeplink",
    DEALER: "Dealer",
    MARKETING_CAMPAIGN: "marketing campaign",
    LOAN_TAB: "loan_tab",
    ROI_DISCOUNT: "roi_discount",
    DETAIL_PAGE: "Detail_page",
    LISTING_PAGE: "LISTING_PAGE",
    CKYC: "ckyc",
    EMI_CALCULATOR_POPUP: "EMI_CALCULATOR_POPUP",
    DETAIL_GET_FINANCING: "detail_Get_Financing",
    LISTING_USP_CF_BANNER: "LISTING_USP_CF_BANNER",
    TEST_DRIVE_BOOKING: "TEST_DRIVE_BOOKING",
    AUTONAUT: "AUTONAUT"
};

export const NAME_ERROR_TEXT = {
    [NAME_ERROR.NAME_FORMAT_ERROR]: "Please avoid special characters and numbers",
    [NAME_ERROR.FULL_NAME_ERROR]: "Enter full name as per PAN"
};

export const postBookingSourceList = [
    FORM_SOURCE.POST_BOOKING,
    FORM_SOURCE.POST_CHECKOUT_ORDER_SUMMARY,
    FORM_SOURCE.TEST_DRIVE_BOOKING
];

export const BROADCASTAPI = {
    TYPE: "autonaut_broadcast",
    STATUS: "success",
    CFSOURCE: "AUTONAUT",
    LOGIN_SUCCESS: "login_success"
};

export const LOAN_TAB_URL = "/used-car-loan";
