import { LOGIN_HEADING_CONSTANTS } from "../../../constants/login-heading-constants";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    popup: null,
    onLoginCallbackProps: {
        callbackType: null,
        payload: null
    },
    showLoginPopup: false,
    loginHeading: "Log in to continue",
    openNewLogin: false,
    newLoginHeading: LOGIN_HEADING_CONSTANTS.DEFAULT,
    loginEventSource: ""
};

export const setActiveLoginPopup = (state = INITIAL_STATE, { popup, openNewLogin, loginEventSource }) => {
    return { ...state, popup, openNewLogin, loginEventSource };
};

export const setOnLoginCallbackProps = (state = INITIAL_STATE, { callbackType, payload, customFunction }) => {
    return { ...state, onLoginCallbackProps: {
        callbackType,
        payload,
        customFunction
    } };
};

export const setShowLoginPopup = (state = INITIAL_STATE,  {showLoginPopup}) => {
    return {...state, showLoginPopup};
};

export const setLoginHeading = (state = INITIAL_STATE, { loginHeading, newLoginHeading }) => {
    return { ...state, loginHeading, newLoginHeading };
};

export const HANDLERS = {
    [Types.ACTIVE_POPUP]: setActiveLoginPopup,
    [Types.SET_ON_LOGIN_CALLBACK_PROPS]: setOnLoginCallbackProps,
    [Types.SET_SHOW_LOGIN_POPUP]: setShowLoginPopup,
    [Types.SET_LOGIN_HEADING]: setLoginHeading
};

export default createReducer(INITIAL_STATE, HANDLERS);
