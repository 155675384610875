import { usedCarLeadInfo, locationPicker, leadData, registratioNo, settings } from "./constants.mobile";

export const migrationsMobile = {
    1: (state) => {
        return {
            ...state,
            usedCarLeadInfo: {
                ...state.usedCarLeadInfo,
                isHomePageCtaFlow: usedCarLeadInfo.isHomePageCtaFlow
            }
        };
    },
    2: (state) => {
        return {
            ...state,
            locationPicker: {
                ...state.locationPicker,
                isCTAClickedWithoutPincode: locationPicker.isCTAClickedWithoutPincode
            },
            leadData: {
                ...state.leadData,
                askUserIntent: leadData.askUserIntent,
                userIntent: leadData.userIntent,
                similarCars: leadData.similarCars
            },
            registratioNo: {
                ...state.registratioNo,
                isError: registratioNo.isError
            },
            settings: {
                ...state.dealerCode,
                dealerCode: settings.dealerCode
            }
        };
    },
    3: (state) => {
        return {
            ...state,
            locationPicker: {
                ...state.locationPicker,
                isManualPincode: locationPicker.isManualPincode
            }
        };
    },
    4: (state) => {
        return {
            leadData: {
                ...state.leadData,
                showCarLoader: leadData.showCarLoader,
                selectedCityId: leadData.selectedCityId
            }
        };
    },
    5: (state) => {
        return {
            leadData: {
                ...state.leadData,
                statsigText: leadData.statsigText
            }
        };
    }
};
