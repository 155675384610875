import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
        UPDATE_TRUECALLER_DETAILS
        RESET_TRUECALLER_DETAILS
    `,
    {
        prefix: "truecaller-listener/"
    }
);
