import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    showAppointmentLocationPicker: false
};

export const toggleAppointmentLocationPicker = (state = INITIAL_STATE, { flag }) => {
    return { ...state, showAppointmentLocationPicker: flag};
};

export const HANDLERS = {
    [Types.TOGGLE_APPOINMENT_LOCATION_PICKER]: toggleAppointmentLocationPicker
};

export default createReducer(INITIAL_STATE, HANDLERS);
