//
// This is the client side entry point for the React app.
//

import React from "react";
// import { render, hydrate } from "react-dom";
import { createRoot, hydrateRoot } from "react-dom/client";
import { routes } from "./routes.mobile";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import thunk from "redux-thunk";
import { PersistGate } from "redux-persist/integration/react";
import { loadableReady } from "@loadable/component";
import handleJwtExpire from "../client/middlewares/handle-jwt-expire";
import { reducerManager } from "./reducer-manager";
import baseReducer from "./reducers/base.mobile";
import { migrationsMobile } from "./migration/mobile";
import {
    initializeSentry,
    sentryReduxEnhancer
} from "../client/utils/sentry/init";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@cars24/turbo-web/lib/theme-provider";

initializeSentry();

const persistKey = "rootMobile";
const whitelistedKeys = [
    // "leadData",
    "settings",
    "location",
    "truecaller",
    "usedCarLeadInfo",
    "marketingLeadForm",
    // "carServiceLeadForm",
    "locationPicker",
    // "savedFilters",
    "registrationNo",
    "preDeliveryInspectionLeadData"
];

const middlewares = [thunk, handleJwtExpire];
const DEBUG = process.env.NODE_ENV === "development";
const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  DEBUG
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension�s options like name
          // actionsBlacklist, actionsCreators, serialize...
      })
      : compose;
const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    sentryReduxEnhancer
);

//
// Redux configure store with Hot Module Reload
//

// const configureStore = initialState => {
//     const store = createStore(persistedReducer, initialState, enhancer);
//     const persistor = persistStore(store);

//     if (module.hot) {
//         module.hot.accept("./reducers/index.mobile", () => {
//             const nextRootReducer = require("./reducers/index.mobile").default;
//             store.replaceReducer(nextRootReducer);
//         });
//     }

//     return { store, persistor };
// };

// const { store, persistor } = reducerManager.configureStore({}, window.__PRELOADED_STATE__, enhancer);
const { store, persistor } = reducerManager.configureStore({
    baseReducer,
    initialState: window.__PRELOADED_STATE__,
    enhancer,
    persistKey,
    whitelistedKeys,
    migration: migrationsMobile
});

const start = App => {
    const jsContent = document.querySelector(".js-content");

    const Application = (
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {() => (
                        <BrowserRouter>
                            <ThemeProvider initialTheme="Base">
                                <App />
                            </ThemeProvider>
                        </BrowserRouter>
                    )}
                </PersistGate>
            </Provider>
        </HelmetProvider>
    );

    if (window.__PRELOADED_STATE__ && jsContent.innerHTML) {
        loadableReady(() => {
            hydrateRoot(jsContent, Application);
        });
    } else {
        loadableReady(() => {
            const root = createRoot(jsContent);
            loadableReady(() => {
                root.render(Application);
            });
        });
    }
};
start(() => renderRoutes(routes));

// window.webappStart = () => start(() => renderRoutes(routes));

//
// Hot Module Reload setup
//
if (module.hot) {
    module.hot.accept("./routes.mobile", () => {
        const r = require("./routes.mobile");
        start(() => renderRoutes(r.routes));
    });
}
