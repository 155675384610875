export const LOGIN_HEADING_CONSTANTS = {
    CAR_LISTING: {
        WISHLIST: {
            heading: "Exclusive offers on your cars!",
            subHeading: "Tap to unlock deals tailored for you."
        },
        CF_BANNER: {
            heading: "Special loan deals await!",
            subHeading: "Explore offers designed for you."
        },
        BEST_MATCHES: {
            heading: "Cars curated for you!",
            subHeading: "Check out your personalised matches."
        },
        CHECK_ELIGIBILITY_CAR_CARD: {
            heading: "Discover your loan options!",
            subHeading: "Find deals tailored to your budget."
        },
        EMI_OFFER: {
            heading: "Discover your loan options!",
            subHeading: "Find deals tailored to your budget."
        }
    },
    CAR_DETAIL: {
        TEST_DRIVE: {
            heading: "Book your test drive!",
            subHeading: "Just one step to experience the car."
        },
        CHECK_ELIGIBILITY: {
            heading: "Loan offers, just for you",
            subHeading: "See your options in seconds."
        },
        CF_CALCULATOR: {
            heading: "Explore loan deals now!",
            subHeading: "Special offers made for you."
        },
        WISHLIST: {
            heading: "Unlock exclusive offers!",
            subHeading: "Your saved cars have surprises for you"
        }
    },
    DEFAULT: {
        heading: "Your next step is just a tap away!",
        subHeading: "Continue your journey in just a few moments."
    }
};
