import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import { POPUP_TYPES } from "./constant";
import { STEP_NAMES } from "../../../constants/app-constants";
import { FORM_STEPS } from "../interest-form-revamp/constant";

const FINANCE_FORM_TABS = [
    { key: "personal", name: "Personal", index: 1 },
    { key: "residential", name: "Residential", index: 2 },
    { key: "income", name: "Income", index: 3 }
];

const getStepindex = (currentStep, tabs = FINANCE_FORM_TABS) => {
    const currentTab = tabs.filter(tab =>
        (currentStep || "").toLowerCase().includes(tab.name.toLowerCase())
    );
    return currentTab[0].index;
};

export const INITIAL_STATE = {
    isVisible: false,
    popUpType: POPUP_TYPES.CF_FORM,
    formQuestions: {},
    // formDetailType: FORM_DETAIL_TYPES.PERSONAL,
    financeQuestionsError: null,
    currentStep: STEP_NAMES.Personal,
    currentStepIndex: getStepindex(STEP_NAMES.Personal),
    currentTabIndex: getStepindex(STEP_NAMES.Personal),
    prevTabIndex: 0,
    cfDetails: {
        cashDownPayment: 0,
        emiAmount: 0,
        preApprovedLoanAmount: 0,
        rateOfInterest: 0,
        tenureInMonths: [],
        cashDownPaymentSlider: {
            range: {
                from: 0,
                to: 0
            },
            step: 0
        }
    },
    name: "",
    gender: "",
    genderKey: "",
    dob: "",
    pancard: "",
    currentResidenceType: "",
    currentResidenceTypeKey: "",
    pinCode: "",
    cibil: null,
    employmentType: "",
    employmentTypeKey: "",
    salary: null,
    incomeProof: "",
    incomeProofKey: "",
    applyLoanError: null,
    currentScreenType: "",
    activeForm: FORM_STEPS.PERSONAL,
    isFinanceLoading: false,
    financePersonalDetail: {},
    financeAddressDetail: {},
    financeIncomeDetail: {},
    submitFinanceAnswersError: null,
    checkCfOfferError: null,
    zeroDpRedirect: false,
    financeCtaFlow: false,
    landingPageFlow: false,
    landingPageFlowLabel: "",
    userVariant: null,
    calculatorPaFlow: false,
    userSelectedDp: null,
    userSelectedTenure: null,
    financeAllOffersData: {
        isLoading: false,
        rejectionDetails: null,
        offer: {}
    },
    config: {},
    userOrder: false,
    cfBannerFlow: false,
    cfModalType: "BOOKING_SUCCESS",
    cfDropType: "",
    isPostBookingCf: false,
    cfLabel: "",
    submittedByFlag: "",
    progressBar: 3,
    dealerOption: "",
    deeplinkRoute: "",
    dealerError: "",
    deeplinkOffer: false,
    isCfCampaign: false,
    cfStatus: "",
    loanTabFlow: false,
    form4submitted: false,
    cfSubmittedBy: "",
    dealerBankingSuccess: false,
    financeFormDataFetched: false,
    cfOfferPageType: "",
    isListingPageBannerFlow: false,
    postBookingCfOrder: {},
    leadId: null,
    isDeeplinkLogin: false,
    bankLogos: null,
    isTenurePopupVisible: false,
    cfTenureData: null,
    rtdAuthToken: null,
    bankingConsent: null
};

export const showCFModal = (state = INITIAL_STATE, { isVisible, currentScreenType }) => {
    return { ...state, isVisible, currentScreenType };
};

export const updateCFPopUpType = (state = INITIAL_STATE, { popUpType }) => {
    return { ...state, popUpType };
};

export const fetchFinanceQuestionsSuccess = (state = INITIAL_STATE, { data }) => {
    const formQuestions = {};
    data.screens.forEach((screen) => {
        formQuestions[screen.heading.toLowerCase()] = screen.questions;
    });
    return {
        ...state,
        formQuestions
    };
};

export const fetchFinanceQuestionsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, financeQuestionsError: error };
};

export const setStep = (state = INITIAL_STATE, { currentStep, tabs }) => {
    const prevTabIndex = state.currentTabIndex;
    const currentTabIndex = getStepindex(currentStep, tabs);
    const currentStepIndex = getStepindex(currentStep, tabs);
    return { ...state, currentStep, currentStepIndex, currentTabIndex, prevTabIndex };
};

export const setPersonalData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financePersonalDetail: { ...state.financePersonalDetail, ...data }
    };
};

export const setAddressData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeAddressDetail: { ...state.financeAddressDetail, ...data }
    };
};

export const setIncomeData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeIncomeDetail: { ...state.financeIncomeDetail, ...data }
    };
};

export const fetchCFDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        cfDetails: data
    };
};

export const fetchCFDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, cfDetails: undefined };
};

export const updateActiveForm = (state = INITIAL_STATE, { activeForm }) => {
    return {
        ...state,
        activeForm
    };
};

const getFinanceFormDataSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        name,
        gender,
        pinCode,
        pancard,
        dateOfBirth,
        addressDetails,
        inComeDetails,
        cibilConsent,
        form1SubmittedBy,
        form2SubmittedBy,
        form3SubmittedBy
    } = data || {};

    const {
        houseType,
        pincode,
        houseNo,
        street,
        city,
        state: stateName
    } = addressDetails || {};

    const {
        employmentType,
        income,
        incomeType,
        monthlyIncome,
        incomeProof,
        emiAmount,
        companyName
    } = inComeDetails || {};

    return {
        ...state,
        financePersonalDetail: {
            nameOnPanCard: name,
            dateOfBirth,
            gender,
            pinCode,
            panNumber: pancard,
            cibilConsent,
            ...state.financePersonalDetail
        },
        financeAddressDetail: {
            houseType,
            houseNo,
            street,
            pincode,
            city,
            state: stateName,
            ...state.financeAddressDetail
        },
        financeIncomeDetail: {
            employmentType,
            monthlyIncome,
            incomeProof,
            companyName,
            income,
            incomeType,
            emiAmount,
            ...state.financeIncomeDetail
        },
        financeFormDataFetched: true,
        financeAddressDetailFetched: addressDetails ? true : false,
        financeIncomeDetailFetched: inComeDetails ? true : false,
        cfForm1Submitted: form1SubmittedBy,
        cfForm2Submitted: form2SubmittedBy,
        cfForm3Submitted: form3SubmittedBy,
        name
    };
};

export const submitFinanceAnswersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...(
            data.offer && {
                selectedOffer: data.offer.selectedOffer
            }
        )
    };
};

export const submitFinanceAnswersFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, submitFinanceAnswersError: error };
};

export const checkCfOfferSuccess = (state = INITIAL_STATE, { data }) => {
    const {
        selectedOffer,
        bankingSkippable,
        bankingSubmitted,
        cfApproved,
        pincodeServiceable,
        preApproved,
        userVariant,
        userDetailsSubmitted,
        offer,
        leadId,
        consentRequired,
        cibil,
        creditVerified
    } = data || {};

    return {
        ...state,
        selectedOffer,
        bankingSkippable,
        bankingSubmitted,
        cfApproved,
        pincodeServiceable,
        preApproved,
        userVariant,
        userDetailsSubmitted,
        userCheckOffer: offer,
        leadId,
        consentRequired,
        cibil,
        creditVerified
    };
};

export const checkCfOfferFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, checkCfOfferError: error };
};

export const setFinanceCTAFlow = (state = INITIAL_STATE, {financeCtaFlow}) => {
    return {...state, financeCtaFlow};
};

export const setLandingPageFlow = (state = INITIAL_STATE, { landingPageFlow }) => {
    return { ...state, landingPageFlow };
};

export const setLandingPageFlowLabel = (state = INITIAL_STATE, { landingPageFlowLabel }) => {
    return { ...state, landingPageFlowLabel };
};

export const setUserVariant = (state = INITIAL_STATE, { userVariant }) => {
    return {...state, userVariant};
};

export const setCalculatorPAFlow = (state = INITIAL_STATE, {calculatorPaFlow}) => {
    return {...state, calculatorPaFlow};
};

export const setUserSelectedDp = (state = INITIAL_STATE, {userSelectedDp}) => {
    return {...state, userSelectedDp};
};

export const setUserSelectedTenure = (state = INITIAL_STATE, {userSelectedTenure}) => {
    return {...state, userSelectedTenure};
};

export const resetCalculatorValue = (state = INITIAL_STATE) => {
    return {...state, userSelectedDp: "", userSelectedTenure: ""};
};

export const getAllOffersSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeAllOffersData: { isLoading: false, ...data }
    };
};

export const getAllOffersFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        financeAllOffersError: error
    };
};
export const setZeroDpRedirectFlow = (state = INITIAL_STATE, {zeroDpRedirect}) => {
    return {...state, zeroDpRedirect};
};

export const setCfModalType = (state = INITIAL_STATE, {cfModalType}) => {
    return {...state, cfModalType};
};

export const setCfDropType = (state = INITIAL_STATE, {cfDropType}) => {
    return {...state, cfDropType};
};

export const setConfig = (state = INITIAL_STATE, {data}) => {
    return {...state, config: {...data}};
};

export const setUserOrder = (state = INITIAL_STATE, {userOrder}) => {
    return {...state, userOrder};
};
export const setCfBannerFlow = (state = INITIAL_STATE, {cfBannerFlow}) => {
    return {...state, cfBannerFlow};
};
export const setIsPostBookingCfFlow = (state = INITIAL_STATE, {isPostBookingCf}) => {
    return {...state, isPostBookingCf};
};

export const setCfLabel = (state = INITIAL_STATE, {cfLabel}) => {
    return {...state, cfLabel};
};
export const setSubmitByFlag = (state = INITIAL_STATE, {submittedByFlag}) => {
    return {...state, submittedByFlag};
};

export const setCurrentScreentType = (state = INITIAL_STATE, {currentScreenType}) => {
    return {...state, currentScreenType};
};

export const setProgressBar = (state = INITIAL_STATE, {progressBar}) => {
    return {...state, progressBar};
};

export const setDealerOption = (state = INITIAL_STATE, {data}) => {
    return {...state, dealerOption: data};
};

export const setDeepLinkRoute = (state = INITIAL_STATE, {data}) => {
    return {...state, deeplinkRoute: data};
};

export const setDealerError = (state = INITIAL_STATE, {data}) => {
    return {...state, dealerError: data};
};

export const setDeepLinkOffer = (state = INITIAL_STATE, {data}) => {
    return {...state, deeplinkOffer: data};
};

export const setIsCfCampaign = (state = INITIAL_STATE, {data}) => {
    return {...state, isCfCampaign: data};
};

export const setCfStatus = (state = INITIAL_STATE, {data}) => {
    return {...state, cfStatus: data};
};

export const setLoanTabFlow = (state = INITIAL_STATE, {data}) => {
    return {...state, loanTabFlow: data};
};

export const setInterestFormFilled = (state = INITIAL_STATE, {data}) => {
    return {...state, form4submitted: data};
};

export const setCfSubmittedBy = (state = INITIAL_STATE, {data}) => {
    return {...state, cfSubmittedBy: data};
};

export const setDealerBankingSuccess = (state = INITIAL_STATE, {data}) => {
    return {...state, dealerBankingSuccess: data};
};

export const setCfOfferPageType = (state = INITIAL_STATE, {data}) => {
    return {...state, cfOfferPageType: data};
};

export const setIsListingPageBannerFlow = (state = INITIAL_STATE, {data}) => {
    return {...state, isListingPageBannerFlow: data};
};

export const clearFormData = (state = INITIAL_STATE) => {
    return {...state, financePersonalDetail: {},
        financeAddressDetail: {},
        financeIncomeDetail: {}
    };
};

export const setPostBookingCfOrder = (state = INITIAL_STATE, {data}) => {
    return {...state, postBookingCfOrder: {...data}};
};

export const setDeeplinkLogin = (state = INITIAL_STATE, {data}) => {
    return {...state, isDeeplinkLogin: data};
};

export const setBankLogoMap = (state = INITIAL_STATE, {data}) => {
    return {...state, bankLogos: {...data}};
};

export const toggleCfTenurePopup = (state = INITIAL_STATE, {data}) => {
    const { isTenurePopupVisible, cfTenureData } = data || {};
    return {
        ...state,
        isTenurePopupVisible,
        cfTenureData
    };
};

export const setRtdAuthToken = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        rtdAuthToken: data
    };
};

export const setBankingConsentData = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        bankingConsent: data
    };
};

export const HANDLERS = {
    [Types.IS_CF_MODAL_VISIBLE]: showCFModal,
    [Types.UPDATE_CF_POPUP_TYPE]: updateCFPopUpType,
    [Types.FETCH_FINANCE_QUESTIONS_SUCCESS]: fetchFinanceQuestionsSuccess,
    [Types.FETCH_FINANCE_QUESTIONS_FAILURE]: fetchFinanceQuestionsFailure,
    [Types.FETCH_CF_DETAILS_SUCCESS]: fetchCFDetailsSuccess,
    [Types.FETCH_CF_DETAILS_FAILURE]: fetchCFDetailsFailure,
    [Types.SET_CURRENT_STEP]: setStep,
    [Types.SET_FINANCE_PERSONAL_DETAILS]: setPersonalData,
    [Types.SET_FINANCE_ADDRESS_DETAILS]: setAddressData,
    [Types.SET_FINANCE_INCOME_DETAILS]: setIncomeData,
    [Types.UPDATE_ACTIVE_FORM]: updateActiveForm,
    [Types.GET_FINANCE_FORM_DATA_SUCCESS]: getFinanceFormDataSuccess,
    [Types.SUMBIT_FINANCE_ANSWERS_SUCCESS]: submitFinanceAnswersSuccess,
    [Types.SUMBIT_FINANCE_ANSWERS_FAILURE]: submitFinanceAnswersFailure,
    [Types.CHECK_CF_OFFER_SUCCESS]: checkCfOfferSuccess,
    [Types.CHECK_CF_OFFER_FAILURE]: checkCfOfferFailure,
    [Types.SET_ZERO_DP_REDIRECT_FLOW]: setZeroDpRedirectFlow,
    [Types.SET_FINANCE_CTA_FLOW]: setFinanceCTAFlow,
    [Types.SET_LANDING_PAGE_FLOW]: setLandingPageFlow,
    [Types.SET_LANDING_PAGE_FLOW_LABEL]: setLandingPageFlowLabel,
    [Types.SET_USER_VARIANT]: setUserVariant,
    [Types.SET_CALCULATOR_PA_FLOW]: setCalculatorPAFlow,
    [Types.SET_USER_SELECTED_DP]: setUserSelectedDp,
    [Types.SET_USER_SELECTED_TENURE]: setUserSelectedTenure,
    [Types.RESET_CALCULATOR_VALUE]: resetCalculatorValue,
    [Types.GET_ALL_OFFER_SUCCESS]: getAllOffersSuccess,
    [Types.GET_ALL_OFFER_FAILURE]: getAllOffersFailure,
    [Types.SET_CONFIG]: setConfig,
    [Types.SET_USER_ORDER]: setUserOrder,
    [Types.SET_CF_BANNER_FLOW]: setCfBannerFlow,
    [Types.SET_CF_MODAL_TYPE]: setCfModalType,
    [Types.SET_CF_DROP_TYPE]: setCfDropType,
    [Types.IS_POST_BOOKING_CF_FLOW]: setIsPostBookingCfFlow,
    [Types.SET_CF_LABEL]: setCfLabel,
    [Types.SET_SUBMIT_BY_FLAG]: setSubmitByFlag,
    [Types.SET_CURRENT_SCREEN_TYPE]: setCurrentScreentType,
    [Types.SET_PROGRESS_BAR]: setProgressBar,
    [Types.SET_DEALER_OPTION]: setDealerOption,
    [Types.SET_DEEPLINK_ROUTE]: setDeepLinkRoute,
    [Types.SET_DEALER_ERROR]: setDealerError,
    [Types.SET_DEEPLINK_OFFER]: setDeepLinkOffer,
    [Types.IS_CF_CAMPAIGN]: setIsCfCampaign,
    [Types.SET_CF_STATUS]: setCfStatus,
    [Types.SET_LOAN_TAB_FLOW]: setLoanTabFlow,
    [Types.SET_INTEREST_FORM_FILLED]: setInterestFormFilled,
    [Types.SET_CF_SUBMITTED_BY]: setCfSubmittedBy,
    [Types.SET_DEALER_BANKING_SUCCESS]: setDealerBankingSuccess,
    [Types.SET_CF_OFFER_PAGE_TYPE]: setCfOfferPageType,
    [Types.SET_IS_LISTING_PAGE_BANNER_FLOW]: setIsListingPageBannerFlow,
    [Types.CLEAR_FORM_DATA]: clearFormData,
    [Types.SET_POST_BOOKING_CF_ORDER]: setPostBookingCfOrder,
    [Types.SET_DEEPLINK_LOGIN]: setDeeplinkLogin,
    [Types.SET_BANK_LOGO_MAP]: setBankLogoMap,
    [Types.TOGGLE_CF_TENURE_POPUP]: toggleCfTenurePopup,
    [Types.SET_RTD_TOKEN]: setRtdAuthToken,
    [Types.SET_BANKING_CONSENT_DATA]: setBankingConsentData
};

export default createReducer(INITIAL_STATE, HANDLERS);
