import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
import removeItemFromArray from "../../../utils/helpers/remove-item-from-array";

export const INITIAL_STATE = {
    data: [],
    flattenedData: [],
    carouselData: [],
    error: null,
    isFetching: false,
    similarToWishlistCars: [],
    similarToWishlistCarsError: null,
    isFetchingSimilarToWishlistCars: false,
    activeCarId: null,
    isWishlistModalVisible: false,
    removedFromWishlistCarId: "",
    wishlistData: {
        content: [],
        cities: {}
    }
};

export const fetchWishlistedCars = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFetching: true,
        similarToWishlistCarsError: null
    };
};

export const fetchWishlistedCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isFetching: false,
        wishlistData: { ...data }
    };
};

export const fetchWishlistedCarsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isFetching: false,
        data: [],
        flattenedData: []
    };
};

export const addToWishlistSuccess = (state = INITIAL_STATE, { carId }) => {
    return {
        ...state,
        flattenedData: [
            ...state.flattenedData,
            carId
        ]
    };
};

export const removeFromWishListSuccess = (state = INITIAL_STATE, { carId }) => {
    return {
        ...state,
        flattenedData: removeItemFromArray(state.flattenedData, carId),
        removedFromWishlistCarId: carId
    };
};

export const fetchSimilarWishlistCars = (state = INITIAL_STATE, { activeCarId }) => {
    return {
        ...state,
        similarToWishlistCars: [],
        similarToWishlistCarsError: null,
        isFetchingSimilarToWishlistCars: false,
        activeCarId
    };
};

export const fetchSimilarToWishlistCarsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        similarToWishlistCars: data,
        similarToWishlistCarsError: null,
        isFetchingSimilarToWishlistCars: false
    };
};

export const fetchSimilarToWishlistCarsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        similarToWishlistCarsError: error,
        isFetchingSimilarToWishlistCars: false
    };
};

export const showWishlistModal = (state = INITIAL_STATE, { isWishlistModalVisible }) => {
    return {
        ...state,
        isWishlistModalVisible
    };
};

export const setWishlistAppIds = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        flattenedData: [...data]
    };
};

const clearRemovedWishlistCarId = (state = INITIAL_STATE) => {
    return {
        ...state,
        removedFromWishlistCarId: ""
    };
};

export const HANDLERS = {
    [Types.FETCH_WISHLISTED_CARS]: fetchWishlistedCars,
    [Types.FETCH_WISHLISTED_CARS_SUCCESS]: fetchWishlistedCarsSuccess,
    [Types.FETCH_WISHLISTED_CARS_FAILURE]: fetchWishlistedCarsFailure,
    [Types.ADD_TO_WISHLIST_SUCCESS]: addToWishlistSuccess,
    [Types.REMOVE_FROM_WISHLIST_SUCCESS]: removeFromWishListSuccess,
    [Types.FETCH_SIMILAR_TO_WISHLIST_CARS]: fetchSimilarWishlistCars,
    [Types.FETCH_SIMILAR_TO_WISHLIST_CARS_SUCCESS]: fetchSimilarToWishlistCarsSuccess,
    [Types.FETCH_SIMILAR_TO_WISHLIST_CARS_FAILURE]: fetchSimilarToWishlistCarsFailure,
    [Types.SHOW_WISHLIST_MODAL]: showWishlistModal,
    [Types.SET_WISHLIST_APP_IDS]: setWishlistAppIds,
    [Types.CLEAR_REMOVED_WISHLIST_CAR_ID]: clearRemovedWishlistCarId
};

export default createReducer(INITIAL_STATE, HANDLERS);
