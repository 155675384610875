export const POPUP_TYPES = {
    LOAN_DETAILS: "LOAN_DETAILS",
    CF_FORM: "CF_FORM",
    LOAN_OFFER: "LOAN_OFFER",
    LOAN_ERROR: "LOAN_ERROR",
    LOAN_CONFIRMATION: "LOAN_CONFIRMATION",
    LOAN_THANK_YOU: "LOAN_THANK_YOU",
    PINCODE_UNSERVICEABLE: "PINCODE_UNSERVICEABLE"
};

export const SUBMIT_BY_TYPES = {
    ZERO_DP: "Zero Dp",
    SEVENTY_TWO_MONTHS: "seventy_two_months",
    POST_BOOKING: "post_booking"
};

export const LANDING_PAGE_LABELS = {
    LISTING_BETWEEN_PAGE: "Listing_between_page",
    HAMBURGER: "Hamburger",
    LISTING_PAGE: "Listing page",
    MENU: "Menu",
    LANDING_PAGE: "landing page"
};

export const CF_PAGE_TYPE = {
    HOME_PAGE: "HOME_PAGE",
    LISTING_PAGE: "LISTING_PAGE",
    DETAIL_PAGE: "DETAIL_PAGE"
};
