import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_REQUEST_CALLBACK_POPUP_STATUS
    UPDATE_CEP_REQUEST_CALLBACK_POPUP_STATUS
    REQUEST_CALL_SUBMITTED_TIME
    IS_TICKING
    SET_CALLBACK_SOURCE
    SET_ASK_CALL_INTENT
    SAEV_CALLBACK_INTENT
`,
    {
        prefix: "requestcallbackmodel/"
    }
);
