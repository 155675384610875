import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    selectedCity: {
        id: null,
        name: null,
        image: null,
        url: null,
        viewAllCarsUrl: null,
        slug: null,
        isVirtualCity: false,
        carServiceCity: null,
        isB2CCity: false
    },
    error: null,
    noData: true,
    enableC2CPromotion: false,
    isCitySelectionSSR: false,
    isCityUnavailable: false
};

const searchCity = (state = INITIAL_STATE, { data }) => {
    return { ...state, mutatedList: [...data], error: null };
};

const selectCity = (state = INITIAL_STATE, { data }) => {
    return { ...state, selectedCity: { ...data }, error: null };
};

export const setData = (state = INITIAL_STATE, { noData }) => {
    return { ...state, noData };
};

export const updatePromotionFlag = (state = INITIAL_STATE, { enableC2CPromotion }) => {
    return { ...state, enableC2CPromotion };
};

export const setCityAvailability = (state = INITIAL_STATE, { isAvailable }) => {
    return { ...state, isCityUnavailable: !isAvailable };
};

export const HANDLERS = {
    [Types.SEARCH_CITY]: searchCity,
    [Types.SELECT_CITY]: selectCity,
    [Types.SET_DATA]: setData,
    [Types.UPDATE_C2C_PROMOTION_FLAG]: updatePromotionFlag,
    [Types.SET_CITY_AVAILABILITY]: setCityAvailability
};

export default createReducer(INITIAL_STATE, HANDLERS);
