import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    TOGGLE_APPOINMENT_LOCATION_PICKER
`,
    {
        prefix: "locationpopup/"
    }
);
