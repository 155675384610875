/* eslint-disable no-magic-numbers */
import { setActiveLoginPopup, setOnLoginCallbackProps } from "../components/mobile/wishlist-login-popup/actions";
import { callbackTypes } from "../components/mobile/wishlist-login-popup/selectors";

// import jwtDecode from "jwt-decode";

const ACTION_WITH_TOKEN = [
    "configuration/CHECK_AUTH_TOKEN"
];

const handleJwtExpire = store => next => action => {
    if (ACTION_WITH_TOKEN.indexOf(action.type) !== -1) {
        // const state = store.getState();
        store.dispatch(setActiveLoginPopup("mobile"));
        store.dispatch(setOnLoginCallbackProps(callbackTypes.TOKEN_EXPIRE));
        // if (state.user.secureToken) {
        //     const authTokenDecoded = jwtDecode(state.user.secureToken);
        //     const currentTimeInSec = new Date().getTime() / 1000;
        //     if (authTokenDecoded.exp - currentTimeInSec < 19800) {
        //         store.dispatch(setActiveLoginPopup("mobile"));
        //     }

        // }
    }

    next(action);
};

export default handleJwtExpire;

