
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    all: [],
    popular: [],
    error: null,
    isLoading: false
};

export const fetchDataSuccess = (state = INITIAL_STATE, { data: { all, popular, luxury = [] } }) => {
    return { ...state, all, popular, luxury, error: null, isLoading: false };
};

export const fetchDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, all: [], popular: [], isLoading: false };
};

export const setIsLoading = (state = INITIAL_STATE, { isLoading }) => {
    return { ...state,  isLoading };
};

export const HANDLERS = {
    [Types.FETCH_LEAD_BRAND_SUCCESS]: fetchDataSuccess,
    [Types.FETCH_LEAD_BRAND_FAILURE]: fetchDataFailure,
    [Types.SET_IS_LOADING]: setIsLoading
};

export default createReducer(INITIAL_STATE, HANDLERS);
