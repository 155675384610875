import { resettableReducer } from "../utils/redux/resettable-reducer";
import { reducerConstants } from "../constants/reducer-constant";
import configReducer from "../components/config-ssr/reducers";
import experimentReducer from "../components/shared/ab-experiment/reducers";
import userReducer from "../components/configuration/reducers";
import sellOnlineCitiesReducer from "../components/mobile/sell-online-cities/reducers";
import toastMessageReducer from "../components/mobile/toast-message/reducers";

//below reducers are used in configuration component hence just adding here for now
import citiesReducer from "../components/mobile/location-picker/reducers";
import filterReducer from "../components/mobile/filters/reducers";
import locationReducer from "../components/location/reducers";
import buyCarPincodeConfigReducer from "../components/buy-car-pincode-config/reducers";

//below reducers are used in mobile layout hence adding here for now
import callBackDetailsReducer from "../components/mobile/request-callback-modal/reducer";
import wishlistLoginReducer from "../components/mobile/wishlist-login-popup/reducers";
import wishlistReducer from "../components/mobile/wishlisted-by-you/reducers";
import consumerFinanceReducer from "../components/mobile/cf-popup/reducers";
import appointmentLocationPicker from "../components/mobile/appointment-location-picker/reducers";
import locationPickerModalReducer from "../components/mobile/location-picker-popup-v2/reducers";
// import carListReducer from "../components/mobile/car-list/reducers";
import chatIconReducer from "../components/mobile/chat-icon/reducers";
import truecallerReducer from "../components/mobile/truecaller-listener/reducers";

//below reducers are used in mobile header
import metaTagReducer from "../components/shared/meta-tags/reducers";
import settingsReducer from "../components/mobile/settings/reducers";
import brandsReducer from "../components/mobile/lead-brands/reducers";
import popularCarListReducer from "../components/mobile/popular-car-list/reducers";
import loginPanelReducer from "../components/mobile/login-panel/reducers";
import loanIntentReducer from "../components/mobile/cf-landing-page-v2/cf-top-banner/reducers";

//below reducers are used in footer
import footerReducer from "../components/mobile/footer/reducers";

export default {
    [reducerConstants.CONFIG]: resettableReducer(reducerConstants.CONFIG)(configReducer),
    [reducerConstants.EXPERIMENTS]: resettableReducer(reducerConstants.EXPERIMENTS)(experimentReducer),
    [reducerConstants.USER]: resettableReducer(reducerConstants.USER)(userReducer),
    [reducerConstants.CITIES]: resettableReducer(reducerConstants.CITIES)(citiesReducer),
    [reducerConstants.FILTERS]: resettableReducer(reducerConstants.FILTERS)(filterReducer),
    [reducerConstants.LOCATION]: resettableReducer(reducerConstants.LOCATION)(locationReducer),
    [reducerConstants.BUY_CAR_PINCODE_CONFIG]: resettableReducer(reducerConstants.BUY_CAR_PINCODE_CONFIG)(buyCarPincodeConfigReducer),
    [reducerConstants.CALLBACK_DETAILS]: resettableReducer(reducerConstants.CALLBACK_DETAILS)(callBackDetailsReducer),
    [reducerConstants.WISHLIST_LOGIN]: resettableReducer(reducerConstants.WISHLIST_LOGIN)(wishlistLoginReducer),
    [reducerConstants.WISHLIST]: resettableReducer(reducerConstants.WISHLIST)(wishlistReducer),
    [reducerConstants.CONSUMER_FINANCE]: resettableReducer(reducerConstants.CONSUMER_FINANCE)(consumerFinanceReducer),
    [reducerConstants.APPT_LOCATION_PICKER]: resettableReducer(reducerConstants.APPT_LOCATION_PICKER)(appointmentLocationPicker),
    [reducerConstants.LOCATION_PICKER]: resettableReducer(reducerConstants.LOCATION_PICKER)(locationPickerModalReducer),
    [reducerConstants.POPULAR_CAR_LIST]: resettableReducer(reducerConstants.POPULAR_CAR_LIST)(popularCarListReducer),
    [reducerConstants.CHAT_ICON]: resettableReducer(reducerConstants.CHAT_ICON)(chatIconReducer),
    [reducerConstants.SELL_ONLINE_CITIES]: resettableReducer(reducerConstants.SELL_ONLINE_CITIES)(sellOnlineCitiesReducer),
    [reducerConstants.META]: resettableReducer(reducerConstants.META)(metaTagReducer),
    [reducerConstants.SETTINGS]: resettableReducer(reducerConstants.SETTINGS)(settingsReducer),
    [reducerConstants.BRANDS]: resettableReducer(reducerConstants.BRANDS)(brandsReducer),
    [reducerConstants.FOOTER]: resettableReducer(reducerConstants.FOOTER)(footerReducer),
    [reducerConstants.TOAST]: resettableReducer(reducerConstants.TOAST)(toastMessageReducer),
    [reducerConstants.TRUECALLER]: resettableReducer(reducerConstants.TRUECALLER)(truecallerReducer),
    [reducerConstants.LOGIN_PANEL]: resettableReducer(reducerConstants.LOGIN_PANEL)(loginPanelReducer),
    [reducerConstants.LOAN_INTENT_DATA]: resettableReducer(reducerConstants.LOAN_INTENT_DATA)(loanIntentReducer)
};
