import { createReducer } from "../../../utils/redux/create-reducer";
import { DEFAULT_CUSTOM_LOCATION_CARD_DETAILS } from "./constant";
import Types from "./types";

export const INITIAL_STATE = {
    showLocationPicker: false,
    locationSelectionType: "",
    pageType: "",
    isCTAClickedWithoutPincode: {
        type: "",
        meta: {}
    },
    ciAvailable: false,
    isComingSoon: false,
    isManualPincode: false,
    initialRender: false,
    changedLocationAfterIndiaPage: {
        isLocationChanged: false,
        city: ""
    },
    customLocationCardDetails: DEFAULT_CUSTOM_LOCATION_CARD_DETAILS,
    focusSearch: false
};

export const toggleLocationPicker = (state = INITIAL_STATE, { status }) => {
    return { ...state, showLocationPicker: status !== undefined ? status : !state.showLocationPicker };
};

export const setLocationSelectionType = (state = INITIAL_STATE, { locationType }) => {
    return { ...state, locationSelectionType: locationType };
};

export const setLocationPageType = (state = INITIAL_STATE, { pageType }) => {
    return { ...state, pageType };
};

export const setIsCTAClickedWithoutPincode = (state = INITIAL_STATE, { isCTAClickedWithoutPincode }) => {
    return { ...state, isCTAClickedWithoutPincode };
};

export const setCiAvailable = (state = INITIAL_STATE, { ciAvailable }) => {
    return {
        ...state,
        ciAvailable
    };
};

export const setComingSoon = (state = INITIAL_STATE, { isComingSoon }) => {
    return {
        ...state,
        isComingSoon
    };
};

export const setIsManualPincode = (state = INITIAL_STATE, {isManualPincode}) => {
    return {
        ...state,
        isManualPincode
    };
};
export const changeLocationAfterIndiaPage = (state = INITIAL_STATE, { isLocationChanged, cityName }) => {
    return {
        ...state,
        changeLocationAfterIndiaPage: {
            isLocationChanged,
            city: cityName
        }
    };
};

export const updateCustomLocationCardDetails = (state = INITIAL_STATE, { heading, imageUrl, geoError }) => {
    return {
        ...state,
        customLocationCardDetails: {
            heading,
            geoError,
            imageUrl
        }
    };
};

export const setCitySearchFocus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        focusSearch: data
    };
};

export const HANDLERS = {
    [Types.TOGGLE_LOCATION_PICKER]: toggleLocationPicker,
    [Types.LOCATION_SELECTETION_TYPE]: setLocationSelectionType,
    [Types.LOCATION_PAGE_TYPE]: setLocationPageType,
    [Types.IS_CTA_CLICKED_WITHOUT_PINCODE]: setIsCTAClickedWithoutPincode,
    [Types.COMING_SOON_POPUP]: setComingSoon,
    [Types.FETCH_CI_AVAILABLE_SUCCESS]: setCiAvailable,
    [Types.SET_MANUAL_PINCODE]: setIsManualPincode,
    [Types.CHANGE_LOCATION_AFTER_INDIA_PAGE]: changeLocationAfterIndiaPage,
    [Types.UPDATE_CUSTOM_LOCATION_CAR_CARD]: updateCustomLocationCardDetails,
    [Types.SET_CITY_SEARCH_FOCUS]: setCitySearchFocus
};

export default createReducer(INITIAL_STATE, HANDLERS);
