export const LOCATION_PAGE_TYPE = {
    homePage: "Buy_used_cars_B2C_Homepage",
    carListing: "Buy_used_cars_B2C_Car_Listing_Page",
    menu: "Menu_Page",
    usedCarHomepage: "Buy_used_cars_B2C_Buy_Used_Car",
    detailPage: "Buy_used_cars_B2C_Car_Details_Page"
};

export const PREVENT_AUTO_SELECT_CITY = [
    "/sell-used-cars/",
    "/sell-used-cars",
    "/sell-marketing",
    "/sell-marketing/"
];

export const DEFAULT_CUSTOM_LOCATION_CARD_DETAILS = {
    heading: "Please setup location",
    imageUrl: "",
    geoError: "We are unable to access your location. Please enable through device settings or select manually."
};
