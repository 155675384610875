export default {
    mobile: "mobile",
    otp: "otp"
};

export const callbackTypes = {
    WISHLIST_POPUP: "WISHLIST_POPUP",
    ADD_TO_FAVOURITE: "ADD_TO_FAVOURITE",
    CF_POPUP: "CF_POPUP",
    SHOW_OUTSIDE_CAR_LOAN_POPUP: "SHOW_OUTSIDE_CAR_LOAN_POPUP",
    MY_BOOKINGS: "MY_BOOKINGS",
    TOKEN_EXPIRE: "TOKEN_EXPIRE",
    BEST_MATCHES: "BEST_MATCHES",
    SERVICE_HISTORY: "SERVICE_HISTORY",
    CUSTOM: "CUSTOM"
};
