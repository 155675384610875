import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import PageLoader from "./components/shared/page-loader";
import withMobileConfiguration from "./pages/mobile/with-mobile-configuration";

const Home = loadable(() => import(/* webpackChunkName: "sell-page" */ "./pages/mobile/home"), { ssr: true, fallback: <PageLoader /> });
const Seo = loadable(() => import("./pages/mobile/seo"), { fallback: <PageLoader />, ssr: true });
const AppLanding = loadable(() => import("./pages/mobile/app-landing"), { fallback: <PageLoader />, ssr: true });
const TokenTermsCondition = loadable(() => import("./pages/mobile/token-terms-conditon"), { fallback: <PageLoader />, ssr: true });
const TermsOfUse = loadable(() => import("./pages/mobile/terms-of-use"), { fallback: <PageLoader />, ssr: true });
const NotFound = loadable(() => import("./pages/mobile/not-found"), { fallback: <PageLoader />, ssr: true });
const NeedHelp = loadable(() => import("./pages/mobile/need-help"), { fallback: <PageLoader />, ssr: true });
const Appointment = loadable(() => import("./pages/mobile/appointment"), { fallback: <PageLoader />, ssr: true });
const Confirmation = loadable(() => import("./pages/mobile/confirmation"), { fallback: <PageLoader />, ssr: true });
const Franchise = loadable(() => import("./pages/mobile/franchise"), { fallback: <PageLoader />, ssr: true });
const ShareExperience = loadable(() => import("./pages/mobile/share-experience"), { fallback: <PageLoader />, ssr: true });
const BuyUsedCarFeedback = loadable(() => import("./pages/mobile/buy-used-cars-feedback"), { fallback: <PageLoader />, ssr: true });
const BuyerSellerDetails = loadable(() => import("./pages/mobile/buyer-seller-details"), { fallback: <PageLoader />, ssr: true });
const CarEvaluation = loadable(() => import("./pages/mobile/car-evaluation"), { fallback: <PageLoader />, ssr: true });
const DealerEvaluation = loadable(() => import("./pages/mobile/dealer-evaluation"), { fallback: <PageLoader />, ssr: true });
const CarValuation = loadable(() => import("./pages/mobile/car-valuation"), { fallback: <PageLoader />, ssr: true });
const RTO = loadable(() => import("./pages/mobile/rto"), { fallback: <PageLoader />, ssr: true });
const Documents = loadable(() => import("./pages/mobile/documents"), { fallback: <PageLoader />, ssr: true });
const Unsubscribe = loadable(() => import("./pages/mobile/unsubscribe"), { fallback: <PageLoader />, ssr: true });
const Refer = loadable(() => import("./pages/mobile/refer"), { fallback: <PageLoader />, ssr: true });
const Cars24Pricing = loadable(() => import("./pages/mobile/cars24-pricing"), { fallback: <PageLoader />, ssr: true });
const Cars24Protect = loadable(() => import("./pages/mobile/cars24-protect"), { fallback: <PageLoader />, ssr: true });
const Referral = loadable(() => import("./pages/mobile/referral"), { fallback: <PageLoader />, ssr: true });
const ClassifiedCars24 = loadable(() => import("./pages/mobile/classified-vs-cars24"), { fallback: <PageLoader />, ssr: true });
const AllianceOffer = loadable(() => import("./pages/mobile/alliance-offer"), { fallback: <PageLoader />, ssr: true });
const AppDownload = loadable(() => import("./pages/mobile/app-download"), { fallback: <PageLoader />, ssr: true });
const VisitExperienceChecklist = loadable(() => import("./pages/mobile/visit-experience-page"), { fallback: <PageLoader />, ssr: true });
const ZendeskComments = loadable(() => import("./pages/mobile/zendesk-comments"), { fallback: <PageLoader />, ssr: true });
const DealerQuestionsContainer = loadable(() => import("./pages/mobile/dealer-questions"), { fallback: <PageLoader />, ssr: true });
const SellOnlineContainer = loadable(() => import("./pages/mobile/sell-online"), { fallback: <PageLoader />, ssr: true });
const SellCarNCD = loadable(() => import("./pages/mobile/sell-car-ncd"), { fallback: <PageLoader />, ssr: true });
const BecomeOurPartner = loadable(() => import("./pages/mobile/become-our-partner"), { fallback: <PageLoader />, ssr: true });
const ScheduleCarCheckup = loadable(() => import("./pages/mobile/schedule-car-check-up"), { fallback: <PageLoader />, ssr: true });
const CreditVerification = loadable(() => import("./components/mobile/credit-verification"), { fallback: <PageLoader />, ssr: true });
const RequestCallback = loadable(() => import("./pages/mobile/request-callback"), { fallback: <PageLoader />, ssr: true });
const Payment = loadable(() => import("./pages/mobile/payment"), { fallback: <PageLoader />, ssr: true });
const EPayment = loadable(() => import("./pages/mobile/e-payment"), { fallback: <PageLoader />, ssr: true });
const OrderPayment = loadable(() => import("./pages/mobile/order-payment"), { fallback: <PageLoader />, ssr: true });
const PartnerOfferPrice = loadable(() => import("./pages/mobile/car-price-offer"), { fallback: <PageLoader />, ssr: true });
const SellOnlineCarEvaluation = loadable(() => import("./components/mobile/sell-onlie-car-evaluation"), { fallback: <PageLoader />, ssr: true });
const SellMarketing = loadable(() => import("./pages/mobile/sell-marketing"), { fallback: <PageLoader />, ssr: true });
const PostInspection = loadable(() => import("./pages/mobile/post-inspection"), { fallback: <PageLoader />, ssr: true });
const SellUsedCar = loadable(() => import("./pages/mobile/sell-used-car"), { fallback: <PageLoader />, ssr: true });
const UserTermsAndConditions = loadable(() => import("./components/mobile/user-consent-terms"), { fallback: <PageLoader />, ssr: true });
const RefurbishmentPage = loadable(() => import("./components/mobile/refurbishment-page"), { fallback: <PageLoader />, ssr: true });
const BankOfferTermsAndConditions = loadable(() => import("./components/mobile/bank-offer-terms-and-conditions"), { fallback: <PageLoader />, ssr: true });
const TestDriveExperienceRating = loadable(() => import("./pages/mobile/test-drive-experience-page"), { fallback: <PageLoader />, ssr: true });
const Caf = loadable(() => import("./components/mobile/caf"), { fallback: <PageLoader />, ssr: true });
const SellOnlinePriceRange = loadable(() => import("./components/mobile/sell-online-price-range"), { fallback: <PageLoader />, ssr: true });
const CarComparison = loadable(() => import("./pages/mobile/car-comparison"), { fallback: <PageLoader />, ssr: true });
const Campaign = loadable(() => import("./pages/mobile/campaign"), { fallback: <PageLoader />, ssr: true });
const DriverVerification = loadable(() => import("./pages/mobile/driver-verification"), { fallback: <PageLoader />, ssr: true });
const TermsAndCondition = loadable(() => import("./pages/mobile/terms-conditions-page"), { fallback: <PageLoader />, ssr: true });
const Nda = loadable(() => import("./pages/mobile/nda-page"), { fallback: <PageLoader />, ssr: true });
const HallOfFame = loadable(() => import("./pages/mobile/hall-of-fame"), { fallback: <PageLoader />, ssr: true });
const PaymentHistoryPage = loadable(() => import("./pages/mobile/payment-history-page"), { fallback: <PageLoader />, ssr: true });
const NeedHelpPageRevamp = loadable(() => import("./pages/mobile/help-page"), { fallback: <PageLoader />, ssr: true });
const TuCibilTerms = loadable(() => import("./pages/mobile/tu-cibil-terms"), { fallback: <PageLoader />, ssr: true });
const PreInspectionDocUpload = loadable(() => import("./pages/mobile/pre-inspection-doc-upload"), { fallback: <PageLoader />, ssr: true });
const KnowMoreNach = loadable(() => import("./pages/mobile/know-more-nach"), { fallback: <PageLoader />, ssr: true });
const RtoDetails = loadable(() => import("./pages/mobile/rto-details"), { fallback: <PageLoader />, ssr: true });
const CfConsent = loadable(() => import("./pages/mobile/cf-cibil-consent"), { fallback: <PageLoader />, ssr: false });
const WhyCars24 = loadable(() => import("./pages/mobile/why-cars24"), { fallback: <PageLoader />, ssr: true });
const LocationPickerMapV2 = loadable(() => import("./pages/mobile/location-picker-map-v2"), { fallback: <PageLoader />, ssr: true });
const LocationPickerSearchV2 = loadable(() => import("./pages/mobile/location-picker-search-v2"), { fallback: <PageLoader />, ssr: true });
const BookAppointmentNonSellOnline = loadable(() => import("./pages/mobile/book-appointment-non-so"), { fallback: <PageLoader />, ssr: true });
const EChallan = loadable(() => import("./pages/mobile/e-challan"), { fallback: <PageLoader />, ssr: true });
const StateEChallan = loadable(() => import("./pages/mobile/state-e-challan"), { fallback: <PageLoader />, ssr: true });
const MyChallans = loadable(() => import("./pages/mobile/my-challans"), { fallback: <PageLoader />, ssr: true });
const MyOrderSummary = loadable(() => import("./pages/mobile/my-order-summary"), { fallback: <PageLoader />, ssr: true });
const MyOrders = loadable(() => import("./pages/mobile/my-orders"), { fallback: <PageLoader />, ssr: true });
const MyOrderStatus = loadable(() => import("./pages/mobile/my-order-status"), { fallback: <PageLoader />, ssr: true });
const ScrapCars = loadable(() => import("./pages/mobile/scrap-cars"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsInfo = loadable(() => import("./pages/mobile/scrap-cars-info"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsFinalPrice = loadable(() => import("./pages/mobile/scrap-cars-final-price"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsSelfInspection = loadable(() => import("./pages/mobile/scrap-cars-self-inspection"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsQuestions = loadable(() => import("./pages/mobile/scrap-cars-questions"), { fallback: <PageLoader />, ssr: true });
const CarServiceHistory = loadable(() => import("./pages/mobile/car-service-history"), { fallback: <PageLoader />, ssr: true });
const CarServiceHistoryInfo = loadable(() => import("./pages/mobile/car-service-history-info"), { fallback: <PageLoader />, ssr: true });
const EmployeeFnF = loadable(() => import("./pages/mobile/employee-fnf"), { fallback: <PageLoader />, ssr: true });
const DiscountOfferTnC = loadable(() => import("./components/mobile/discount-offer-tnc"), { fallback: <PageLoader />, ssr: true });
const BankOfferTermsAndConditionsPopup = loadable(() => import("./components/mobile/bank-offer-terms-and-condition-popup"), {fallback: <PageLoader />, ssr: true});
const RegNumberDetails = loadable(() => import("./pages/mobile/reg-number-details"), { fallback: <PageLoader />, ssr: false });
const UserMobile = loadable(() => import("./pages/mobile/user-mobile"), {fallback: <PageLoader />, ssr: false});
const UserIntent = loadable(() => import("./pages/mobile/user-intent"), {fallback: <PageLoader />, ssr: false});
const Fourdoor = loadable(() => import("./pages/mobile/fourdoor"), { fallback: <PageLoader />, ssr: true });
const BookCarWash = loadable(() => import("./pages/mobile/book-car-wash"), { fallback: <PageLoader />, ssr: true });
const MyProfile = loadable(() => import("./pages/mobile/my-profile"), { fallback: <PageLoader />, ssr: false });
const AccountLogin = loadable(() => import("./pages/mobile/account-login"), { fallback: <PageLoader />, ssr: true });
const UserAppointments = loadable(() => import("./pages/mobile/user-appointments"), { fallback: <PageLoader />, ssr: false });
const CarHealth = loadable(() => import("./pages/mobile/car-health"), { fallback: <PageLoader />, ssr: false });
const SellerVasReportDownload = loadable(() => import("./pages/mobile/seller-vas-report-download"), { fallback: <PageLoader />, ssr: false });
const ImmersiveView = loadable(() => import("./pages/mobile/immersive-view"), { fallback: <PageLoader />, ssr: false });
const PreDeliveryInspection = loadable(() => import("./pages/mobile/pre-delivery-inspection"), { fallback: <PageLoader />, ssr: true });
const VehicleInformation = loadable(() => import("./pages/mobile/vehicle-information"), { fallback: <PageLoader />, ssr: false });
const PreDeliveryInspectionAppointment = loadable(() => import("./pages/mobile/pre-delivery-inspection-appointment"), { fallback: <PageLoader />, ssr: false });
const VehicleRegInformation = loadable(() => import("./pages/mobile/vehicle-reg-information"), { fallback: <PageLoader />, ssr: false });
const PreDeliveryInspectionConfirmation = loadable(() => import("./pages/mobile/pre-delivery-inspection-confirmation"), { fallback: <PageLoader />, ssr: false });
const CarExchangeOffer = loadable(() => import("./pages/mobile/car-exchange-offer"), { fallback: <PageLoader />, ssr: true });
const FranchiseForm = loadable(() => import("./pages/mobile/franchise-form"), { fallback: <PageLoader />, ssr: false });
const SellerFinanceScreen = loadable(() => import("./pages/mobile/seller-finance"), {fallback: <PageLoader />, ssr: false});
const SFForm = loadable(() => import("./pages/mobile/sf-form"), { fallback: <PageLoader />, ssr: false });
const FileViewer = loadable(() => import("./pages/mobile/file-viewer"), { fallback: <PageLoader />, ssr: false });
const EChallanPartner = loadable(() => import("./pages/mobile/e-challan-partner"), { fallback: <PageLoader />, ssr: true });
const CarServiceHistoryPartner = loadable(() => import("./pages/mobile/car-service-history-partner"), { fallback: <PageLoader />, ssr: true });
const LuxuryCar = loadable(() => import("./pages/mobile/luxury-cars"), { fallback: <PageLoader />, ssr: true });
const PriceView = loadable(() => import("./pages/mobile/price-view"), { fallback: <PageLoader />, ssr: true });
const RtoShare = loadable(() => import("./pages/mobile/rto-share-container"), { fallback: <PageLoader />, ssr: true });
const SFLandingPage = loadable(() => import("./pages/mobile/sf-landing-page"), { fallback: <PageLoader />, ssr: true });
const PLForm = loadable(() => import("./pages/mobile/pl-form"), { fallback: <PageLoader />, ssr: true});
const SellerOffer = loadable(() => import("./pages/mobile/seller-offer"), { ssr: false, fallback: <PageLoader />});
const SellerPrePriceQuestionContainer = loadable(() => import("./pages/mobile/seller-pre-price-question-container"), { fallback: <PageLoader />, ssr: false });
const SpinAndWinWrapper = loadable(() => import("./pages/mobile/spin-the-wheel-v2"), { fallback: <PageLoader />, ssr: true });
const SellOnlineQuestionarre = loadable(() => import("./pages/mobile/sell-online-questionarre"), { fallback: <PageLoader />, ssr: false });
const SellOnlineOfferedPriceWrapper = loadable(() => import("./pages/mobile/sell-online-offered-price"), { fallback: <PageLoader />, ssr: false });
const SellOnlineConfirmationWrapper = loadable(() => import("./pages/mobile/sell-online-confirmation"), { fallback: <PageLoader />, ssr: true });
const PricePlan = loadable(() => import("./pages/mobile/price-plan"), { fallback: <PageLoader />, ssr: false });
const StudioPhotos = loadable(() => import("./pages/mobile/studio-photos"), {fallback: <PageLoader />, ssr: false});
const StudioPhotosStatus = loadable(() => import("./pages/mobile/studio-photos-status"), {fallback: <PageLoader />, ssr: false});

const Root = ({ route, children }) => {
    return (
        <React.Fragment>
            {renderRoutes(route.routes)}
            {children}
        </React.Fragment>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.object
};

const routes = [
    {
        path: "/",
        component: withRouter(withMobileConfiguration(Root)),
        init: "./init-top.mobile",
        routes: [
            {
                path: "/sell-used-cars",
                exact: true,
                isSellCarRoute: true,
                name: "home",
                component: Home,
                init: "./home.mobile"
            },
            {
                path: "/seller-offer/:token",
                exact: true,
                name: "seller-offer",
                component: SellerOffer,
                init: "./seller-offer.mobile"
            },
            {
                path: "/campaign",
                exact: true,
                name: "campaign",
                component: Campaign,
                init: "./campaign.mobile"
            },
            {
                path: "/request-callback",
                exact: true,
                name: "request-callback",
                component: RequestCallback
            },
            {
                path: "/franchise",
                exact: true,
                name: "franchise",
                component: Franchise,
                init: "./franchise.mobile"
            },
            {
                path: "/franchise-form",
                exact: true,
                name: "franchiseForm",
                component: FranchiseForm
            },
            {
                path: "/security/terms-and-condition",
                exact: true,
                name: "terms-and-condition",
                component: TermsAndCondition
            },
            {
                path: "/security/nda",
                exact: true,
                name: "nda",
                component: Nda
            },
            {
                path: "/security/hall-of-fame",
                exact: true,
                name: "hall-of-fame",
                component: HallOfFame
            },
            {
                path: "/spin-the-wheel",
                exact: true,
                component: SpinAndWinWrapper,
                init: "./spin-and-win.mobile"
            },
            {
                path: "/tokenpayment/:customerType(buyer|seller)/:dealHash",
                exact: true,
                name: "e-payment",
                component: EPayment,
                init: "./e-payment.mobile"
            },
            {
                path: "/unsubscribe/:token",
                exact: true,
                isUsedCarRoute: true,
                component: Unsubscribe,
                name: "unsubscribe"
            },
            {
                path: "/sell-online",
                exact: true,
                name: "sell-online",
                component: SellOnlineContainer,
                init: "./sell-car-online.mobile"
            },
            {
                path: "/user-mobile",
                exact: true,
                name: "sell-online",
                component: UserMobile
            },
            {
                path: "/seller-intent",
                exact: true,
                name: "sell-online",
                component: UserIntent
            },
            {
                path: "/seller-finance",
                exact: true,
                name: "sell-online",
                component: SellerFinanceScreen
            },
            {
                path: "/sell-online-evaluation/:tab?/:section?",
                exact: true,
                name: "sell-online",
                component: SellOnlineCarEvaluation,
                init: "./sell-online.mobile"
            },
            {
                path: "/sell-online/appointment/index",
                exact: true,
                name: "sell-online",
                component: SellOnlinePriceRange,
                init: "./sell-online-price-range.mobile"
            },
            {
                path: "/used-car-partner",
                exact: true,
                name: "new-car-partner",
                component: SellCarNCD,
                init: "./used-car-partner.mobile"
            },
            {
                path: "/map-view/:token",
                exact: true,
                name: "map-view",
                component: LocationPickerMapV2,
                init: "./map-view.mobile"
            },
            {
                path: "/price-plan",
                exact: true,
                name: "price-plan",
                component: PricePlan,
                init: "./price-plan.mobile"
            },
            {
                path: "/location-search/:token",
                exact: true,
                name: "location-search",
                component: LocationPickerSearchV2,
                init: "./location-search.mobile"
            },
            {
                path: "/sell-marketing",
                exact: true,
                isSellCarRoute: true,
                name: "sell-marketing",
                component: SellMarketing,
                init: "./sell.marketing.mobile"
            },
            {
                path: "/car-exchange-offer",
                exact: true,
                isSellCarRoute: true,
                name: "car-exchange-offer",
                component: CarExchangeOffer,
                init: "./car-exchange-offer.mobile"
            },
            {
                path: "/tu-cibil-terms",
                exact: true,
                name: "tu-cibil-terms",
                component: TuCibilTerms
            },
            {
                path: "/partner-offer",
                exact: true,
                name: "partner-price",
                component: PartnerOfferPrice,
                init: "./partner-offer-price.mobile"
            },
            {
                path: "/sell-online-questionarre/:token",
                exact: true,
                name: "sell-online-questionarre",
                component: SellOnlineQuestionarre
            },
            {
                path: "/sell-online-questionarre/appointment/:token",
                exact: true,
                name: "sell-online-questionarre-appointment",
                component: SellOnlineOfferedPriceWrapper
            },
            {
                path: "/sell-online-questionarre/appointment/confirmation/:token",
                exact: true,
                name: "sell-online-questionarre-appointment-confirmation",
                component: SellOnlineConfirmationWrapper,
                init: "./appointment-confirmation.mobile"
            },
            {
                path: "/partner-questions",
                exact: true,
                name: "partner-questions",
                component: DealerQuestionsContainer,
                init: "./sell-online-questions.mobile"
            },
            {
                path: "/reschedule-appointment/:reschedule(address|slot|registration|booked)?",
                exact: true,
                name: "sell-online-price",
                component: ScheduleCarCheckup,
                init: "./sell-online-offer-price.mobile"
            },
            {
                path: "/referral",
                exact: true,
                name: "refer",
                component: Refer,
                init: "./referral.mobile"
            },
            {
                path: "/refer",
                exact: true,
                name: "refer",
                component: Refer,
                init: "./referral.mobile"
            },
            {
                path: "/refer/share",
                exact: true,
                name: "refer-share",
                component: Refer,
                init: "./refer.mobile"
            },
            {
                path: "/order-payment/:token",
                exact: true,
                component: OrderPayment
            },
            {
                path: "/documents",
                exact: true,
                name: "documents",
                component: Documents
            },
            {
                path: "/rto-vehicle-registration-details:query?",
                exact: true,
                name: "rto",
                component: RTO,
                init: "./rto.mobile"
            },
            {
                path: "/rto-details",
                exact: true,
                name: "rto-details",
                component: RtoDetails,
                init: "./rto.mobile"
            },
            {
                path: "/vehicle-details",
                exact: true,
                name: "vehicle-details",
                component: RtoShare,
                init: "./rto.share.mobile"
            },
            {
                path: "/used-car-valuation",
                exact: true,
                isSellCarRoute: true,
                name: "used-car-evaluation",
                component: CarValuation,
                init: "./car-valuation.mobile"
            },
            {
                path: "/terms-of-use",
                exact: true,
                name: "terms-of-use",
                component: TermsOfUse
            },
            {
                path: "/offer/:slug",
                exact: true,
                name: "marketing",
                component: AllianceOffer,
                init: "./alliance-offer.mobile"
            },
            {
                path: "/need-help",
                exact: true,
                name: "need-help",
                component: NeedHelp,
                init: "./need-help.mobile"
            },
            {
                path: "/:feedback(resolution-feedback)",
                exact: true,
                name: "need-help-feedback",
                component: NeedHelp,
                init: "./need-help.mobile"
            },
            {
                path: "/buy-used-cars/user-consent/v1/terms-and-conditions",
                exact: true,
                name: "user-consent-terms",
                component: UserTermsAndConditions
            },
            {
                path: "/token/terms-and-conditions",
                exact: true,
                name: "token-terms-and-conditions",
                component: TokenTermsCondition
            },
            {
                path: "/buyer-details/:id",
                exact: true,
                name: "buyer-details",
                component: BuyerSellerDetails,
                init: "./buyer-seller-details.mobile"
            },
            {
                path: "/seller-details/:id",
                exact: true,
                name: "seller-details",
                component: BuyerSellerDetails,
                init: "./buyer-seller-details.mobile"
            },
            {
                path: "/dealer-onboarding",
                exact: true,
                component: BecomeOurPartner,
                init: "./become-our-partner.mobile"
            },
            {
                path: "/become-our-partner",
                exact: true,
                component: BecomeOurPartner,
                init: "./become-our-partner.mobile"
            },
            {
                path: "/mobile-apps",
                exact: true,
                name: "mobile-apps",
                component: AppLanding
            },
            {
                path: "/app-download",
                exact: true,
                name: "app-download",
                component: AppDownload,
                init: "./app-download"
            },
            {
                path: "/car-evaluation",
                exact: true,
                name: "car-evaluation",
                component: CarEvaluation,
                init: "./car-evaluation.mobile"
            },
            {
                path: "/dealer-evaluation",
                exact: true,
                name: "dealer-evaluation",
                component: DealerEvaluation,
                init: "./dealer-evaluation.mobile"
            },
            {
                path: "/customer-review/submit/:appointmentID",
                exact: true,
                name: "customer-review-submission",
                component: Referral,
                init: "./customer-review-submission.mobile"
            },
            {
                path: "/customer-review/:appointmentID",
                exact: true,
                name: "customer-review",
                component: ShareExperience,
                init: "./customer-review.mobile"
            },
            {
                path: "/appointment/index",
                exact: true,
                name: "appointment",
                component: Appointment,
                init: "./appointment.mobile"
            },
            {
                path: "/questionarre/:token",
                exact: true,
                name: "seller-pre-price-questions",
                component: SellerPrePriceQuestionContainer,
                init: "./pre-price-question.mobile"
            },
            {
                path: "/price-view/:token",
                exact: true,
                name: "price-view",
                component: PriceView,
                init: "./appointment.mobile"
            },
            {
                path: "/appointment/index/:screen(address|slot)/:token",
                exact: true,
                name: "appointment",
                component: BookAppointmentNonSellOnline,
                init: "./appointment.mobile"
            },
            {
                path: "/appointment/confirmation",
                exact: true,
                name: "appointment-confirmation",
                component: Confirmation,
                init: "./appointment-confirmation.mobile"
            },
            {
                path: "/buy-used-cars/feedback/:formId?",
                exact: true,
                component: BuyUsedCarFeedback,
                name: "buy-used-cars-feedback"
            },
            {
                path: "/sell-used-cars/feedback",
                exact: true,
                component: BuyUsedCarFeedback,
                name: "sell-used-cars-feedback"
            },
            {
                path: "/caf/:id",
                exact: true,
                component: Caf,
                init: "./caf.mobile"
            },
            {
                path: "/cars24-pricing",
                exact: true,
                component: Cars24Pricing
            },
            {
                path: "/classified-vs-cars24",
                exact: true,
                component: ClassifiedCars24
            },
            {
                path: "/car-comparison",
                exact: true,
                isUsedCarRoute: true,
                component: CarComparison,
                name: "car-comparison",
                init: "./comparison.mobile"
            },
            {
                path: "/:slug1?/:slug2?/:slug3?/:slug4?/testimonials",
                exact: true,
                name: "seo-testimonials",
                component: () => null,
                init: "./seo-testimonials.mobile"
            },
            {
                path: "/cars24-protect",
                exact: true,
                name: "cars24-protect",
                component: Cars24Protect
            },
            {
                path: "/support-comments/:id",
                exact: true,
                name: "support-comments",
                component: ZendeskComments
            },
            {
                path: "/credit-verification",
                exact: true,
                name: "credit-verification",
                component: CreditVerification
            },
            {
                path: "/help",
                exact: true,
                isUsedCarRoute: true,
                component: NeedHelpPageRevamp,
                name: "need-help-page",
                init: "./help-page.mobile"
            },
            {
                path: "/seller-financing/:token",
                exact: true,
                name: "seller-form",
                component: SFForm
            },
            {
                path: "/visit-experience-checklist/:orderId",
                exact: true,
                name: "visit-experience-checklist",
                component: VisitExperienceChecklist,
                init: "./visit-experience-page.mobile"
            },
            {
                path: "/visit-rating/:orderId",
                exact: true,
                name: "visit-rating",
                component: TestDriveExperienceRating,
                init: "./test-drive-experience.mobile"
            },
            {
                path: "/transaction-record/:carId",
                exact: true,
                name: "transaction-record",
                component: PaymentHistoryPage,
                init: "./payment-history-page.mobile"
            },
            {
                path: "/inspection-complete/:token",
                exact: true,
                name: "inspection-complete",
                component: PostInspection,
                init: "./post-inspection-journey.mobile"
            },
            {
                path: "/reg-number-details",
                exact: true,
                name: "reg-number-details",
                component: RegNumberDetails
            },
            {
                path: "/pre-inspection-doc-upload/:token",
                exact: true,
                name: "pre-inspection-doc-upload",
                component: PreInspectionDocUpload,
                init: "./pre-inspection.mobile"
            },
            {
                path: "/driver-verification/:id",
                exact: true,
                name: "driver-verification",
                component: DriverVerification,
                init: "./driver-verfication.mobile"
            },
            {
                path: "/why-cars24/:category?",
                exact: true,
                name: "why-cars24",
                component: WhyCars24,
                init: "./why-cars24.mobile"
            },
            {
                path: "/payment/checkout/:slug/:orderId",
                exact: true,
                name: "payment-checkout",
                component: Payment
            },
            {
                path: "/sell-used-car-:city",
                exact: true,
                name: "sell-used-car",
                isSellCarRoute: true,
                component: SellUsedCar,
                init: "./sell.used.car.mobile"
            },
            {
                path: "/traffic-challan",
                exact: true,
                name: "traffic-challan",
                component: EChallan,
                init: "./traffic.challan.mobile"
            },
            {
                path: "/traffic-challan-partner",
                exact: true,
                name: "traffic-challan-partner",
                component: EChallanPartner,
                init: "./traffic.challan.mobile"
            },
            {
                path: "/pre-delivery-inspection",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspection,
                init: "./pre.delivery.inspection.mobile"
            },
            {
                path: "/pre-delivery-inspection/vehicle-information",
                exact: true,
                name: "pre-delivery-inspection",
                component: VehicleInformation
            },
            {
                path: "/pre-delivery-inspection/vehicle-reg-information",
                exact: true,
                name: "pre-delivery-inspection",
                component: VehicleRegInformation
            },
            {
                path: "/pre-delivery-inspection/slot/:token",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspectionAppointment
            },
            {
                path: "/pre-delivery-inspection/confirmation/:token",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspectionConfirmation
            },
            {
                path: "/scrap-cars",
                exact: true,
                name: "scrap-cars",
                component: ScrapCars,
                init: "./scrap.cars.mobile"
            },
            {
                path: "/scrap-cars/info/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsInfo,
                init: "./scrap.cars.info.mobile"
            },
            {
                path: "/scrap-cars/final-price/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsFinalPrice,
                init: "./scrap.cars.final.price.mobile"
            },
            {
                path: "/scrap-cars/self-inspection/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsSelfInspection,
                init: "./scrap.cars.self.inspection.mobile"
            },
            {
                path: "/scrap-cars/questions/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsQuestions,
                init: "./scrap.cars.questions.mobile"
            },
            {
                path: "/:state-traffic-challan-status",
                exact: true,
                name: "traffic-challan",
                component: StateEChallan,
                init: "./state.challan.mobile"
            },
            {
                path: "/my-challans/:token?/:action?/:orderId?",
                exact: true,
                name: "traffic-challan",
                component: MyChallans,
                init: "./my.challan.mobile"
            },
            {
                path: "/order-summary/:orderNumber",
                exact: true,
                name: "order-summary",
                component: MyOrderSummary,
                init: "./order.summary.mobile"
            },
            {
                path: "/order-status/:orderNumber/:token?",
                exact: true,
                name: "order-status",
                component: MyOrderStatus,
                init: "./order.status.mobile"
            },
            {
                path: "/my-orders",
                exact: true,
                name: "my-orders",
                component: MyOrders
            },
            {
                path: "/refurbishment-standards",
                exact: true,
                isUsedCarRoute: true,
                component: RefurbishmentPage,
                name: "refurbishment-standards",
                init: "./refurbishment-page.mobile"
            },
            {
                path: "/offer-terms-and-conditions",
                exact: true,
                isUsedCarRoute: true,
                component: BankOfferTermsAndConditions,
                name: "offer-terms-and-conditions"
            },
            {
                path: "/discount-offer-tnc",
                exact: true,
                isUsedCarRoute: true,
                component: DiscountOfferTnC,
                name: "discount-offer-tnc"
            },
            {
                path: "/bank-offer-tnc",
                exact: true,
                isUsedCarRoute: true,
                component: BankOfferTermsAndConditionsPopup,
                name: "bank-offer-tnc"
            },
            {
                path: "/cibil-consent",
                exact: true,
                name: "cibil-consent",
                isUsedCarRoute: true,
                component: CfConsent
            },
            {
                path: "/know-more",
                exact: true,
                name: "know-more",
                isUsedCarRoute: true,
                component: KnowMoreNach
            },
            {
                path: "/sell-used-luxury-cars",
                exact: true,
                isSellCarRoute: true,
                name: "sell-used-luxury-cars",
                component: LuxuryCar,
                init: "./luxury-car.mobile"
            },
            {
                path: "/employee/settlement",
                exact: true,
                name: "employee-fnf",
                isUsedCarRoute: true,
                component: EmployeeFnF
            },
            {
                path: "/car-service-history",
                exact: true,
                name: "car-service-history",
                component: CarServiceHistory,
                init: "./car-service-history.mobile"
            },
            {
                path: "/car-service-history-partner",
                exact: true,
                name: "car-service-history-partner",
                component: CarServiceHistoryPartner,
                init: "./car-service-history.mobile"
            },
            {
                path: "/car-service-history-info/:token?",
                exact: true,
                name: "car-service-history",
                component: CarServiceHistoryInfo,
                init: "./car-service-history-info.mobile"
            },
            {
                path: "/fourdoor",
                exact: true,
                name: "fourdoor",
                component: Fourdoor
            },
            {
                path: "/book-car-wash/:token?",
                exact: true,
                name: "bookcarwash",
                component: BookCarWash,
                init: "./book-car-wash.mobile"
            },
            {
                path: "/account",
                exact: true,
                name: "account-login",
                component: AccountLogin
            },
            {
                path: "/account/profile",
                exact: true,
                name: "my-profile",
                component: MyProfile
            },
            {
                path: "/account/login",
                exact: true,
                name: "account-login",
                component: AccountLogin
            },
            {
                path: "/account/appointments",
                exact: true,
                name: "user-appointments",
                component: UserAppointments
            },
            {
                path: "/car-health/:token?",
                exact: true,
                name: "car-health",
                component: CarHealth
            },
            {
                path: "/car-health-report-download/:token",
                exact: true,
                name: "car-health",
                component: SellerVasReportDownload
            },
            {
                path: "/seller-vas-report-download/:token",
                exact: true,
                name: "seller-vas-report-download",
                component: SellerVasReportDownload
            },
            {
                path: "/file-viewer",
                exact: true,
                name: "file-viewer",
                component: FileViewer
            },
            {
                path: "/immersive-view/:slide",
                exact: true,
                name: "immersive-view",
                component: ImmersiveView
            },
            {
                path: "/loan-against-car",
                exact: true,
                name: "sf-landing-page",
                component: SFLandingPage,
                init: "./sf-landing.mobile"
            },
            {
                path: "/personal-loan",
                exact: true,
                name: "personal-loan",
                component: PLForm,
                init: "./pl-form.mobile"
            },
            {
                path: "/studio-photos/:id",
                exact: true,
                name: "studio-photos",
                component: StudioPhotos
            },
            {
                path: "/studio-photos-status/:orderNumber",
                exact: true,
                name: "studio-photos-status",
                component: StudioPhotosStatus
            },
            {
                path: "/:slug1?/:slug2?/:slug3?/:slug4?",
                exact: true,
                name: "seo",
                component: Seo,
                init: "./seo.mobile"
            },
            {
                path: "*",
                exact: true,
                name: "not-found",
                component: NotFound
            }
        ]
    }
];

export { routes };
