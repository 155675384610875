import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_POPULAR_CITY_LIST_AND_BRANDS
    FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS
    FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE
    UPDATE_POPULAR_CITY_LIST_SSR_STATUS
    `,
    {
        prefix: "popular-car-list/"
    }
);
