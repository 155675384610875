import { NUMBER } from "../../constants/app-constants";

export const getUpdatedRecentSearches = (selectedValue, recentSearches) => {
    const { value } = selectedValue;
    let options = [...recentSearches];
    options.forEach((item, index) => {
        if (item.value === value) {
            options.splice(index, 1);
        }
    });
    if (options.length === NUMBER.THREE) options.pop(); //for fifo
    options = [selectedValue, ...options]; //treating it like queue instead of stack
    return options;
};
